import {colorPalette} from '@sharefiledev/antd-config';
import {Badge, Button, Flex, Popover, Space, Typography} from 'antd';
import {Col, Row} from 'antd';
import {useRef, useState} from 'react';

import {ContactMultiSelect} from '../../../../common/contactMultiselect/ContactMultiSelect';
import {DateRangePicker} from '../../../../common/date-range-picker/DateRangePicker';
import {MultiSelect} from '../../../../common/multi-select/MultiSelect';
import {
    FlexConatainer,
    HeaderFilterText,
} from '../../../../components/filter-options/FilterOptions.styled';
import {FilterIcon} from '../../../../svg-icons/FilterIcon';
import {t} from '../../../../utils';
import {InputObj} from '../../../Layouts';
import {useTableContext} from '../../context/TableContext';
import {ColumnDefinition} from '../../DataTable';
import {TableAction} from '../../header';

const {Title, Text} = Typography;

type Props = {
    items: ColumnDefinition[];
    getAppliedFilters: () => string[];
    onClearFilters: (showLoader: boolean) => void;
    timeZoneOffsetMins?: number;
    roleMapper?: Record<string, string>;
};

export const Filter = ({
    items,
    getAppliedFilters,
    onClearFilters,
    timeZoneOffsetMins,
    roleMapper,
}: Props) => {
    const [open, setOpen] = useState(false);
    const {onColumnAction, filterValues, userList} = useTableContext();

    const firstComponentRef = useRef<any>(null);

    /**
     * open and closes the popover.
     * @param newOpen
     */
    const handleOpenChange = (newOpen: boolean) => {
        setOpen(newOpen);
    };

    /**
     * callback funtion from the selected component, to update the keys.
     * @param name selected property name.
     * @param values selected component values.
     */
    const onSelectChange = (name: string, {value}: any) => {
        const updatedFilters: any = {...filterValues};
        if (value.length === 0) {
            delete updatedFilters[name];
        } else {
            updatedFilters[name] = value;
        }
        onColumnAction({
            action: TableAction.FILTER,
            value: {name: name, filterValues: updatedFilters, showLoader: false},
        });
    };

    /**
     * This function returns the component based on schema component type.
     * @param item Schema property
     * @param index number
     * @returns Returns the component based on schema component type.
     */
    const renderComponent = (item: ColumnDefinition, index: number) => {
        const component: string = item.component;
        const FilterMapper: {
            [key in string]: any;
        } = {
            Select: MultiSelect,
            AccountUserMultiSelect: ContactMultiSelect,
            DateOverdueField: DateRangePicker,
            AssigneeField: ContactMultiSelect,
        };
        const Component = FilterMapper[component];
        return (
            <Component
                {...item}
                ariaLabel={item.label}
                index={index}
                value={filterValues && filterValues[item.name]}
                onChange={(e: InputObj) => onSelectChange(item.name, e)}
                isFilter
                forwardedRef={index === 0 ? firstComponentRef : null}
                timeZoneOffsetMins={timeZoneOffsetMins}
                userList={userList}
                roleMapper={roleMapper}
                dateFormat={item?.attributes?.format}
            />
        );
    };

    /**
     * Renders the content inside popover.
     * @returns List of components.
     */
    const renderContent = () => {
        return (
            <FlexConatainer data-testid="filter-popover">
                <Row gutter={[16, 24]}>
                    {items.map((item, index) => {
                        return (
                            <Col
                                className="gutter-row"
                                span={window.innerWidth <= 650 ? 24 : 12}
                                key={index}
                            >
                                <div style={{minWidth: '270px'}}>
                                    <Text>{item.label}</Text>
                                    <div style={{marginTop: '8px'}}>
                                        {renderComponent(item, index)}
                                    </div>
                                </div>
                            </Col>
                        );
                    })}
                </Row>
            </FlexConatainer>
        );
    };

    /**
     * This function renders customized title.
     * @returns Title text  and clear button.
     */
    const renderTitle = () => {
        return (
            <Flex justify="space-between" align="center">
                <Flex style={{padding: '4px 16px'}} wrap="wrap" gap="small">
                    <Title level={5}>{t('dynamic-components:filters.header')}</Title>
                    <HeaderFilterText>
                        {getAppliedFilters().length} {t('dynamic-components:filters.applied')}
                    </HeaderFilterText>
                </Flex>
                <Button type="link" onClick={() => onClearFilters(false)}>
                    {t('dynamic-components:filters.clear')}
                </Button>
            </Flex>
        );
    };

    return (
        <Popover
            content={open ? renderContent() : <></>}
            placement="bottomLeft"
            title={() => renderTitle()}
            trigger="click"
            open={open}
            onOpenChange={handleOpenChange}
            afterOpenChange={(open) => open && firstComponentRef?.current?.focus()}
        >
            <Button
                id="filter-table-btn"
                data-testid="filter-table-btn"
                type="text"
                style={{background: open ? colorPalette.lavender2 : ''}}
            >
                <Space>
                    <FilterIcon open={open} />
                    <Text style={{color: open ? colorPalette.lavender8 : ''}}>
                        {t('dynamic-components:filters.buttonText')}
                    </Text>
                    <Badge
                        count={getAppliedFilters().length}
                        showZero
                        color={colorPalette.lavender6}
                    />
                </Space>
            </Button>
        </Popover>
    );
};
