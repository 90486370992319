import styled from '@emotion/styled';
import {colorPalette, sizes} from '@sharefiledev/antd-config';
import {Flex} from 'antd';

export const HeaderText = styled.span`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    font-style: normal;
`;

export const DataTableStyles = styled.div<{recordsLength: number}>`
    .open-btn {
        display: none;
        align-items: center;
        margin-right: 4px;
    }
    .input-field {
        width: 100%;
        height: 100%;
        border-radius: 0px;
    }
    .ant-table-placeholder {
        display: flex;
        justify-content: center;
        height: auto;
        align-items: center;
        .ant-table-cell {
            border: none;
        }
    }
    th: hover {
        background: #f3f3f3 !important;
        color: #000;
        cursor: pointer;
        .sort-image {
            display: contents;
        }
    }

    .ant-table-footer {
        display: flex;
        background: white !important;
        border-left: none !important;
        border-right: none !important;
        border-top: 1px solid #d9d9d9 !important;
        border-bottom: 1px solid #d9d9d9 !important;
        border-radius: 0 !important;
        height: 48px;
        padding: 4px 16px;
        .footer-style {
            display: flex;
            width: 100%;
            align-items: center;
            cursor: pointer;
            position: relative;
            right: 12px;
            .add-row {
                color: ${colorPalette.lavender6};
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                margin-left: 4px;
            }
        }
    }

    #newRow-btn {
        width: fit-content;
    }

    .ant-table-cell-row-hover {
        background: #f3f3f3 !important;
        .open-btn {
            display: block;
        }
    }
    .ant-table-container {
        border-inline-start: none !important;
        border-top: 1px solid #d9d9d9 !important;
        border-start-start-radius: 0;
        border-start-end-radius: 0;
    }
    .ant-table-header > table > thead > tr > th {
        border-inline-end: 1px solid #d9d9d9 !important;
        border-bottom: 1px solid #d9d9d9 !important;
    }

    .ant-table-header > table > thead > tr > th:last-child {
        border-inline-end: none !important;
    }

    .ant-table-thead {
        .ant-table-cell {
            padding: 0 16px;
            height: 30px;
            background: #fff;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            color: #555;
        }
    }

    .ant-editable-cell {
        margin-left: 2px;
    }

    .ant-table-row {
        td:last-child {
            .textinput-field {
                right: 220px !important;
            }
        }
        td:first-child {
            .table-cell {
                display: flex;
                justify-content: space-between;
            }
            .table-cell:focus {
                .open-btn {
                    display: block;
                }
            }
        }

        td:first-child .table-cell.edit .open-btn {
            display: none;
        }

        .ant-table-cell:not(:last-child) {
            border-inline-end: 1px solid #d9d9d9 !important;
        }

        .ant-table-cell {
            height: 48px;
            border-bottom: 1px solid #d9d9d9 !important;
            padding: 0px;
            display: flex;
            align-items: center;
            .ant-input-outlined:focus,
            .ant-select-focused.ant-select-outlined:not(.ant-select-disabled):not(
                    .ant-select-customize-input
                ):not(.ant-pagination-size-changer)
                .ant-select-selector,
            .ant-picker.ant-picker-outlined.ant-picker-focused {
                outline: none;
            }
            .table-cell {
                height: 100%;
                width: 100%;
            }
            // overflow: hidden;
            white-space: nowrap;
            .select-field,
            .date-field {
                width: 100%;
                z-index: 1;
                bottom: 4px;
            }
            // .currency-field,
            .numeric-field {
                position: relative;
                bottom: 6px;
            }
            .textarea-field,
            .textinput-field,
            .currency-field {
                position: relative;
                z-index: 1;
            }
            .multi-select {
                display: flex;
                width: 100%;
                height: 100%;
                align-items: center;
                .ant-select {
                    width: 100%;
                    height: 100%;
                    .ant-select-selector > .ant-select-selection-placeholder {
                        padding-right: 14px;
                    }
                }
            }
            .contact-multi-select {
                position: relative;
                z-index: 1;
                bottom: 5px;
                right: 16px;
            }
            .avatar-group {
                width: 100%;
                overflow: hidden;
                white-space: nowrap;
                span {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
            .ant-anchor-link-title {
                color: #1677ff;
            }
            .link-field {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                text-decoration: underline;
                color: #1677ff;
            }
            .input-field .ant-select-selector {
                width: 100%;
                height: 100%;
                border-radius: 0px;
            }
            .comments-btn-grp {
                display: flex;
                align-items: center;
            }
        }

        .editable-cell-value-wrap.view {
            overflow: hidden;
        }
        .editable-cell-value-wrap {
            box-sizing: border-box;
            height: 100%;
            width: 100%;
            align-items: center;
            display: flex;
            white-space: nowrap;
            text-overflow: ellipsis;
            word-break: keep-all;
            // padding: 4px 0;
            .overdue-badge {
                position: relative;
                bottom: 8px;
            }
            span:first-child {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
        .editable-cell-value-wrap.view,
        .editable-cell-value-wrap.edit .padded-row {
            height: 100%;
            width: 100%;
            align-items: center;
            box-sizing: border-box;
            padding: 0px ${sizes.base};
        }
        .padded-row-bordered {
            padding: 0px 14px !important;
            border: 2px solid #6356f6;
        }
        .textarea-field textarea {
            width: 400px;
        }
    }
    .ant-table-row:last-child {
        .textarea-field {
            top: ${({recordsLength}) => (recordsLength > 1 ? '-8px !important' : '0px !important')};
        }
        .textarea-field textarea {
            ${({recordsLength}) => recordsLength < 2 && 'padding: 0px 11px;'}
        }
    }

    .ant-input-number-input-wrap {
        padding-right: 12px;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type='number'] {
        -moz-appearance: textfield;
    }
`;

export const addColumnBtnStyle: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
    color: colorPalette.lavender6,
    lineHeight: 16,
    height: 15,
};

export const FlexContainerOuter = styled(Flex)`
    // align-items: center;
    // justify-content: center;
    gap: ${sizes.MS};
    padding-top: ${sizes.MD};
`;

export const FlexContainerInner = styled(Flex)`
    align-items: center;
    justify-content: center;
    gap: ${sizes.SM};
    max-width: 600;
    text-align: center;
    text-wrap: balance;
`;
export const StyledDropdown = styled.div`
    .anticon.anticon-check {
        display: none;
    }
    .ant-row {
        .ant-col:first-child {
            display: flex;
            justify-content: center;
            align-items: center;
            .ant-avatar {
                background-color: ${colorPalette.lavender4};
            }
        }
    }
`;
