import {Avatar, Tooltip} from '@citrite/citrix-ui';
import {useAsync} from 'react-async-hook';

import {loadAssigneeUsers} from '../../../api/sfApi';
import {Loader} from '../../../common/loader/Loader';
import {AVATAR_LIMIT} from '../../../constants/constants';
import {AvatarFieldProps} from '../Fields';
import {AvatarGroupContainer, AvatarsContainer, AvatarText} from '../Fields.styled';
import {stringToArray} from '../utils';

export const AvatarViewField = ({fieldValue}: AvatarFieldProps) => {
    const overflowCount = fieldValue?.length - AVATAR_LIMIT;
    const {loading, result} = useAsync(async () => {
        return await loadAssigneeUsers(fieldValue as string[]);
    }, []);
    const avatarValue = stringToArray(fieldValue);

    const getInitials = (fullname: string) => {
        const names = fullname.split(' ');
        let initials = names[0].substring(0, 1).toLocaleUpperCase();
        names.length > 1
            ? (initials += names[names.length - 1].substring(0, 1).toLocaleUpperCase())
            : (initials += '');
        return initials;
    };

    const getAvatarUrl = (userId: string) => {
        const hostnameParts = window.location.hostname.split('.');
        hostnameParts[0] = 'sf-assets';
        return `https://${hostnameParts.join('.')}/avatar/${userId}`;
    };
    if (loading) {
        return <Loader height="30px" />;
    }

    return (
        <AvatarGroupContainer className="avatar-group">
            <AvatarsContainer>
                {avatarValue &&
                    Array.isArray(avatarValue) &&
                    avatarValue.slice(0, AVATAR_LIMIT).map((user: string) => {
                        const person = result && result.find((item: any) => item.Id == user);
                        if (person)
                            return (
                                <Tooltip
                                    hoverTrigger={
                                        <Avatar
                                            label={getInitials(person.FullName)}
                                            src={getAvatarUrl(person.Id)}
                                            size={32}
                                        />
                                    }
                                    placement="top"
                                    key={person?.Id}
                                >
                                    {person?.FullName}
                                </Tooltip>
                            );
                        return null;
                    })}
            </AvatarsContainer>
            {avatarValue.length === 1 && <AvatarText>{result?.[0]?.FullName ?? ''}</AvatarText>}
            {overflowCount > 0 && (
                <Tooltip
                    hoverTrigger={
                        <AvatarText>
                            {'+'}
                            {overflowCount}
                        </AvatarText>
                    }
                    placement="top"
                >
                    {avatarValue.slice(AVATAR_LIMIT).map((user: string) => {
                        const person = result?.find((item: {Id: string}) => item.Id === user);
                        return person ? <div key={person.Id}>{person.FullName}</div> : null;
                    })}
                </Tooltip>
            )}
        </AvatarGroupContainer>
    );
};
