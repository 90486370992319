import {capabilities as projectsCapabilities} from '@sharefiledev/projects-pilet';
import {Capabilities, CapabilityRecord} from '@sharefiledev/sfplugin-capability-contracts';
import {RID} from '@sharefiledev/sfplugin-resource-id';

import {getTable, getTableRecord} from '../api/dcsApi';
import {TableEntityType} from './extensionTypes';

export const TableRecordQueryParamName = 'record';
export const SolutionActiveTabParamName = 'activeTab';

const getProjectNavigationDetails = async (projectRID: string) => {
    return await projectsCapabilities[TableEntityType.project].getNavigationDetails(projectRID);
};

const tableCapabilities: Capabilities = {
    async getNavigationDetails(requestedByResourceId) {
        const tableRID = getResourceRID(requestedByResourceId, TableEntityType.table);

        const [tableDetails, projectsNavigationDetail] = await Promise.all([
            getTable(requestedByResourceId),
            getProjectNavigationDetails(tableRID.toString()),
        ]);

        return {
            name: tableDetails?.schema?.schemaLabel,
            webAppPath: `${projectsNavigationDetail?.webAppPath}/${tableRID.entityType}-${tableRID.entityId}`,
        };
    },
};

const recordCapabilities: Capabilities = {
    async getNavigationDetails(requestedByResourceId) {
        const tableRID = getResourceRID(requestedByResourceId, TableEntityType.table);
        const recordRID = getResourceRID(requestedByResourceId, TableEntityType.record);

        const [projectsNavigationDetail, recordDetails] = await Promise.all([
            getProjectNavigationDetails(tableRID.toString()),
            getTableRecord(requestedByResourceId),
        ]);

        const searchParams = new URLSearchParams();
        searchParams.set(
            TableRecordQueryParamName,
            `${recordRID.entityType}-${recordRID.entityId}`
        );
        searchParams.set(SolutionActiveTabParamName, tableRID.toString());

        const recordWebPath = `${projectsNavigationDetail?.webAppPath}/${tableRID.entityType}-${
            tableRID.entityId
        }?${searchParams.toString()}`;
        {
            return {
                name: recordDetails.title,
                webAppPath: recordWebPath,
            };
        }
    },
};

const getResourceRID = (resourceId: string, resourceType: TableEntityType) => {
    const rid = RID.parse(resourceId);
    const tableRID = rid.findEntityType(resourceType);
    if (!tableRID) {
        throw new Error(`No Project resource found for: ${resourceId}`);
    }
    return tableRID;
};

export const capabilities: CapabilityRecord = {
    [TableEntityType.table]: tableCapabilities,
    [TableEntityType.record]: recordCapabilities,
};
