import {Select} from 'antd';
import {DefaultOptionType} from 'antd/es/select';
import {useCallback, useEffect, useState} from 'react';

import {SelectFieldProps} from '../Fields';
import {useFieldOperations} from '../hooks/useFieldOperations';
import {SingleSelectText} from '../View/Select';

export const SingleSelect = ({
    attributes,
    fieldValue,
    required,
    validations,
    onChange,
    onCancel,
    forwardedRef,
    className,
    dataTestId,
    name,
    fieldType,
    disabled,
}: SelectFieldProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const [dropDownOptions, setDropDownOptions] = useState<DefaultOptionType[]>([]);
    const {inputValue, handleOnBlur, handleOnChange, handleKeyDown} = useFieldOperations(
        fieldType,
        fieldValue,
        required,
        validations,
        onChange,
        onCancel
    );

    useEffect(() => {
        if (attributes.defaultValue && !fieldValue) handleOnChange(attributes.defaultValue);
    }, []);

    const optionBuilder = useCallback(() => {
        const selectTextProps = {
            attributes,
            fieldValue,
            required,
            validations,
            onChange,
            onCancel,
            forwardedRef,
            name,
            fieldType,
        };
        const options: DefaultOptionType[] = [];
        const enumConfig = attributes.enumConfig;
        if (enumConfig) {
            for (const item in enumConfig) {
                const isEnumDeleted = enumConfig[item].isDeleted ?? false; // backward compatiblity for older schemas
                if (!isEnumDeleted) {
                    options.push({
                        label: (
                            <SingleSelectText
                                {...selectTextProps}
                                fieldValue={item}
                                dataTestId={dataTestId}
                            />
                        ),
                        value: item,
                    });
                }
            }
        }

        setDropDownOptions(options);
    }, [attributes]);

    useEffect(() => {
        optionBuilder();
    }, [optionBuilder]);

    const onChangeSelect = (value: string) => {
        if (!value) return;
        handleOnChange(value);
    };

    const handleKey = (event: React.KeyboardEvent<any>) => {
        !isOpen && handleKeyDown(event);
    };

    const onDropdownVisibleChange = (open: boolean) => {
        setIsOpen(open);
    };

    return (
        <Select
            className={className}
            value={inputValue?.toString()}
            options={dropDownOptions}
            onChange={onChangeSelect}
            onBlur={handleOnBlur}
            autoFocus
            ref={forwardedRef}
            style={{flexGrow: 1}}
            data-testid={dataTestId}
            onKeyDown={handleKey}
            onDropdownVisibleChange={onDropdownVisibleChange}
            disabled={disabled}
        />
    );
};
