import {
    Add,
    AlarmClock,
    BulletList,
    CalendarMark,
    CheckCircle,
    Close,
    CoinStack,
    DoubleCheck,
    InformationCircleFilled,
    Kanban,
    LinkChain,
    MenuDotVertical,
    MultiSelect,
    Paperclip,
    RecycleBin,
    SignHashtag,
    Table,
    TextEditingTyping,
    TextFields,
    UserSingleNeutral,
    WarningCircleFilled,
    WarningOctagonFilled,
} from '@sharefiledev/icons';

import withDefaultSize from './IconComponent';

type IconComponentProps = {
    size?: number;
    color?: string;
    style?: React.CSSProperties;
    tabIndex?: number;
};

type IconComponent = React.ComponentType<IconComponentProps & React.HTMLProps<HTMLElement>>;

interface Icons {
    Paperclip: IconComponent;
    Close: IconComponent;
    Add: IconComponent;
    InformationCircleFilled: IconComponent;
    BulletList: IconComponent;
    CalendarMark: IconComponent;
    Kanban: IconComponent;
    Table: IconComponent;
    CoinStack: IconComponent;
    LinkChain: IconComponent;
    MultiSelect: IconComponent;
    SignHashtag: IconComponent;
    TextEditingTyping: IconComponent;
    TextFields: IconComponent;
    UserSingleNeutral: IconComponent;
    MenuDotVertical: IconComponent;
    WarningCircleFilled: IconComponent;
    RecycleBin: IconComponent;
    CheckCircle: IconComponent;
    WarningOctagonFilled: IconComponent;
    AlarmClock: IconComponent;
    DoubleCheck: IconComponent;
}

export const Icons: Icons = {
    Paperclip: withDefaultSize(Paperclip),
    Close: withDefaultSize(Close),
    Add: withDefaultSize(Add),
    InformationCircleFilled: withDefaultSize(InformationCircleFilled),
    BulletList: withDefaultSize(BulletList),
    CalendarMark: withDefaultSize(CalendarMark),
    Kanban: withDefaultSize(Kanban),
    Table: withDefaultSize(Table),
    CoinStack: withDefaultSize(CoinStack),
    LinkChain: withDefaultSize(LinkChain),
    MultiSelect: withDefaultSize(MultiSelect),
    TextEditingTyping: withDefaultSize(TextEditingTyping),
    TextFields: withDefaultSize(TextFields),
    UserSingleNeutral: withDefaultSize(UserSingleNeutral),
    SignHashtag: withDefaultSize(SignHashtag),
    MenuDotVertical: withDefaultSize(MenuDotVertical),
    WarningCircleFilled: withDefaultSize(WarningCircleFilled),
    RecycleBin: withDefaultSize(RecycleBin),
    CheckCircle: withDefaultSize(CheckCircle),
    WarningOctagonFilled: withDefaultSize(WarningOctagonFilled),
    AlarmClock: withDefaultSize(AlarmClock),
    DoubleCheck: withDefaultSize(DoubleCheck),
};
