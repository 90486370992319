import {useState} from 'react';

import {FieldType} from '../../../enum';
import {InputType} from '../Fields';
import {validateInput} from '../utils';

export const useFieldOperations = <T extends InputType>(
    fieldType: FieldType,
    fieldValue: T,
    required: boolean,
    validations: any,
    onChange: (value: T, isValid?: boolean, focusCell?: boolean) => void,
    onCancel: (focusCell: boolean) => void
) => {
    const [inputValue, setInputValue] = useState(() => fieldValue);
    const [isError, setIsError] = useState(false);
    const isForm = fieldType === FieldType.Form;

    const handleOnBlur = () => {
        !isForm && onChange(inputValue, !isError);
    };

    const handleOnChange = (value: T) => {
        setInputValue(value);
        setIsError(!!validateInput(value, required, validations));
        isForm && onChange(value);
    };

    const handleKeyDown = (event: React.KeyboardEvent<any>) => {
        if (isForm) return;
        if (event.key === 'Enter' || event.key === 'Tab') {
            event.key === 'Tab' && event.preventDefault();
            onChange(inputValue, !isError, true);
        } else if (event.key === 'Escape') {
            onCancel(true);
        }
    };

    return {
        inputValue,
        handleOnChange,
        isError,
        handleOnBlur,
        handleKeyDown,
        validateInput,
    } as const;
};
