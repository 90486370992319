import {Select, Tag} from 'antd';
import {memo, useMemo} from 'react';

import {COLORS} from '../../constants/constants';
import {FieldProperties, TagRender} from '../../layouts/Layouts';

const {Option} = Select;

export interface MultiSelectFieldProps extends FieldProperties {
    attributes: {
        defaultValue: string;
        enum: [string];
        enumConfig: {
            [status: string]: {
                badgeType: string;
                displayName: string;
            };
        };
    };
    value: [string] | [];
    index: number;
}

export const MultiSelect = memo(
    ({attributes, value, onChange, ariaLabel, forwardedRef}: MultiSelectFieldProps) => {
        const {enum: enumValues, enumConfig} = attributes;

        const optionBuilder = useMemo(() => {
            return enumValues.map((item) => ({
                label: enumConfig[item].displayName,
                value: item,
            }));
        }, [enumValues, enumConfig]);

        const tagRender: TagRender = ({label, value, closable, onClose}) => {
            const {badgeType} = enumConfig?.value ?? {badgeType: 'default'};
            const {background, color} = COLORS[badgeType];
            const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
                event.preventDefault();
                event.stopPropagation();
            };
            return (
                <Tag
                    bordered={false}
                    style={{marginInlineEnd: 4, background, color}}
                    onMouseDown={onPreventMouseDown}
                    closable={closable}
                    onClose={onClose}
                >
                    {value ?? label}
                </Tag>
            );
        };

        return (
            <div className="select-field">
                <Select
                    mode="multiple"
                    placeholder="Select..."
                    maxTagCount="responsive"
                    value={value}
                    onChange={(e) => onChange({value: e, errorMessage: ''})}
                    tagRender={tagRender}
                    style={{width: '100%'}}
                    aria-label={`${ariaLabel} multi-select`}
                    autoFocus
                    allowClear
                    ref={forwardedRef}
                >
                    {optionBuilder.map(({value, label}) => (
                        <Option key={value} value={value} label={label}>
                            <Tag
                                bordered={false}
                                style={{
                                    background: COLORS[enumConfig[value].badgeType].background,
                                    color: COLORS[enumConfig[value].badgeType].color,
                                }}
                            >
                                {label}
                            </Tag>
                        </Option>
                    ))}
                </Select>
            </div>
        );
    }
);
