import {storageExtensionSlots, StorageFileTableParams} from '@sharefiledev/storage-plugin-pilet';

import {useAppContext} from '../../../../AppContext';

interface Props {
    containerRID: string;
}

export const FileBrowserCounter = ({containerRID}: Props) => {
    const {piletApi} = useAppContext();
    const Extension = piletApi.Extension as React.FC<any>;

    const filesTableParams: StorageFileTableParams = {
        containerRID,
    };

    return <Extension name={storageExtensionSlots.Count} params={filesTableParams} />;
};
