import {Button, Typography} from 'antd';
import React from 'react';

import {NoRecords} from '../../../../svg-icons/NoRecords';
import {t} from '../../../../utils';
import {FlexContainerInner, FlexContainerOuter} from '../../DataTable.styled';

const {Text} = Typography;

export interface FilteredTableEmptyStateProps {
    onClearFilters: (showLoader: boolean) => void;
}

export const FilteredTableEmptyState: React.FC<FilteredTableEmptyStateProps> = ({
    onClearFilters,
}) => {
    return (
        <FlexContainerOuter vertical>
            <NoRecords />
            <FlexContainerInner vertical>
                {
                    <Text strong style={{fontSize: 16}}>
                        {t('dynamic-components:filtered_empty_state.title')}
                    </Text>
                }
                {<Text>{t('dynamic-components:filtered_empty_state.message')}</Text>}
            </FlexContainerInner>
            <Button id="clearFilters-btn" onClick={() => onClearFilters(true)}>
                {t('dynamic-components:filtered_empty_state.buttonLabel')}
            </Button>
        </FlexContainerOuter>
    );
};
