import styled from '@emotion/styled';
import {sizes} from '@sharefiledev/antd-config';

export const MainWrapper = styled.div`
    height: calc(100% - 1rem);
    margin: 1rem 0 0;
`;

export const StyledExportModal = styled.div`
    .ant-modal-close {
        top: ${sizes.MS};
        right: ${sizes.base};
        .ant-modal-close-x {
            size: ${sizes.base};
        }
    }
`;
