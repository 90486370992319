import {colorPalette} from '@sharefiledev/antd-config';
import {Add} from '@sharefiledev/icons';
import {Button, Table, TableColumnsType} from 'antd';
import {useEffect, useRef, useState} from 'react';

import {ColumnWidth} from '../../enum';
import {RichTextContextProvider} from './context/RichText';
import {TableProvider} from './context/TableContext';
import CustomCell from './CustomCell';
import {DataTableProps, TableColumnRenderer} from './DataTable';
import {DataTableStyles} from './DataTable.styled';
import {TableAction} from './header';
import {ResizableTitle} from './header/ResizableHeader';
import useGenerateTableColumns from './hooks/useGenerateColumns';
import {Toolbar} from './toolbar';
import {FilteredTableEmptyState} from './toolbar/filters/FilteredTableEmptyState';

export const DataTable = (props: DataTableProps) => {
    const [tableHeight, setTableHeight] = useState(0);
    const elementRef = useRef<any>(null);
    const tblRef: Parameters<typeof Table>[0]['ref'] = useRef(null);
    const {
        columns,
        rows,
        isLoading,
        sort,
        operations,
        onInlineCreateRow,
        onColumnAction,
        nonSortableColumns,
        filterValues,
        allowedColumnFields,
        createButtonLabel,
        allowedCurrencies,
        userList,
        showTableFields,
        createRowButtonDisabled,
        createRowButtonLoading,
        customColumns,
        timeZoneOffsetMins,
        enableComments,
        roleMapper,
        allowedDateFormat,
    } = props;

    const isEmpty = rows.length === 0;
    const filtersApplied = filterValues && Object.keys(filterValues).length > 0;
    const canAddColumn = operations?.canAddColumn ?? false;
    const filteredColumn = showTableFields ?? columns;

    const {columns: tableColumns, totalColumnWidth} = useGenerateTableColumns(
        filteredColumn,
        sort,
        canAddColumn,
        customColumns,
        timeZoneOffsetMins,
        roleMapper
    );
    const canCreateInlineRow = operations?.canCreateInlineRow ?? false;
    useEffect(() => {
        const calculateRemainingHeight = () => {
            // fetching total window height
            const windowHeight = window.innerHeight;

            // gives offset height from the elementRef element
            const elementOffsetTop = elementRef.current?.offsetTop ?? 0;

            // Calculate the remaining height by subtracting the element's top offset from the window height
            const remainingHeight = windowHeight - elementOffsetTop - 235;
            setTableHeight(remainingHeight);
        };

        // Initially setting height for the data-table
        calculateRemainingHeight();

        // setting a new height to data-table when the browser resizes.
        const handleResize = () => {
            calculateRemainingHeight();
        };

        // adding listner event to window for browser resize
        window.addEventListener('resize', handleResize);

        if (isEmpty && !filtersApplied) {
            onInlineCreateRow && onInlineCreateRow();
        }

        return () => {
            // destroying browser resize listner event
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    /**
     * Creates a new row in the data table and sets focus on it.
     *
     * This function is responsible for adding a new row to the table's data model.
     * The newly created row will automatically receive focus, allowing for immediate user interaction (e.g., editing fields).
     */
    const createRow = () => {
        // if empty row is created in table, will not create other one.
        if (rows.filter((record: any) => record?._id === 'addrecord').length === 0) {
            onInlineCreateRow && onInlineCreateRow();
        }

        // scroll position will move to last empty row.
        tblRef.current?.scrollTo({index: rows.length});
        // Focus on the first editable cell of the newly created row
        const focusTimeout = setTimeout(() => {
            const rowSelectors = elementRef.current.querySelectorAll('.ant-table-row');
            rowSelectors[rowSelectors.length - 1]?.childNodes[0]?.childNodes[0]?.click();
            clearTimeout(focusTimeout);
        }, 0);
    };

    /**
     * function renders a new row
     * @returns return element.
     */
    const renderFooter = () => {
        return (
            <div
                id="newRow-btn"
                className="footer-style"
                onClick={createRow}
                onKeyDown={createRow}
                tabIndex={-1}
            >
                <Button
                    tabIndex={0}
                    type="text"
                    size="small"
                    aria-label={createButtonLabel}
                    data-testid="create-row-btn"
                >
                    <Add color={colorPalette.lavender6} />
                    <div className="add-row">{createButtonLabel}</div>
                </Button>
            </div>
        );
    };

    const customComponent = {
        header: {
            cell: ResizableTitle,
        },
        body: {
            cell: CustomCell,
        },
    };
    const calculateTableWidth = () => {
        const tableViewPortWidth = document
            .querySelector('.ant-table-wrapper')
            ?.getBoundingClientRect().width;
        const maxColumns = (tableViewPortWidth ?? window.innerWidth) / ColumnWidth.MIN_WIDTH;
        if (columns.length > maxColumns) {
            return 'max-content';
        }
        return totalColumnWidth;
    };

    /***
     * gives applied filters length.
     */
    const getAppliedFilters = () => {
        if (!filterValues || typeof filterValues !== 'object') {
            return [];
        }

        return Object.keys(filterValues).filter((key) => filterValues[key].length > 0);
    };

    /**
     * This function clears all the applied filter values,
     * if filters are applied.
     */
    const onClearFilters = (showLoader: boolean) => {
        if (getAppliedFilters().length > 0) {
            onColumnAction({
                action: TableAction.FILTER,
                value: {name: '', filterValues: {}, showLoader},
            });
        }
    };

    const getEmptyText = () => {
        if (isEmpty && filtersApplied) {
            return <FilteredTableEmptyState onClearFilters={onClearFilters} />;
        }
        return <></>;
    };

    return (
        <TableProvider
            operations={operations}
            onColumnAction={onColumnAction}
            nonSortableColumns={nonSortableColumns}
            filterValues={filterValues}
            allowedColumnFields={allowedColumnFields}
            allowedCurrencies={allowedCurrencies}
            allowedDateFormat={allowedDateFormat}
            userList={userList}
            enableComments={enableComments}
            roleMapper={roleMapper}
        >
            <Toolbar
                createRowButtonDisabled={createRowButtonDisabled}
                createRowButtonLoading={createRowButtonLoading}
                columns={columns}
                createButtonLabel={createButtonLabel ?? ''}
                getAppliedFilters={getAppliedFilters}
                onClearFilters={onClearFilters}
                timeZoneOffsetMins={timeZoneOffsetMins}
                roleMapper={roleMapper}
            />
            <RichTextContextProvider>
                <DataTableStyles ref={elementRef} recordsLength={rows.length}>
                    <Table
                        bordered
                        components={customComponent}
                        columns={tableColumns as TableColumnsType<TableColumnRenderer>}
                        dataSource={isLoading ? [] : rows}
                        pagination={false}
                        ref={tblRef}
                        virtual
                        scroll={{x: calculateTableWidth(), y: tableHeight}}
                        footer={canCreateInlineRow ? renderFooter : undefined}
                        locale={{
                            emptyText: getEmptyText(),
                        }}
                        data-testid="data-table-body"
                    />
                </DataTableStyles>
            </RichTextContextProvider>
        </TableProvider>
    );
};
