import {Typography} from 'antd';
import {useEffect} from 'react';

import {CurrencyEnum} from '../../../../constants/fields';
import {t} from '../../../../utils';
import {CurrencyArray} from '../../../Layouts';
import {FullWidthSelect, InputWrapperFlex, LabelStyle} from './ManageColumnModal.styled';

// Function to create currency options
const createCurrencyOption = (currency: CurrencyEnum) => ({
    label: (
        <LabelStyle key={currency}>
            {t(`dynamic-components:manageField.fields.currencyField.currency.${currency}`)}
        </LabelStyle>
    ),
    value: currency,
});

interface CurrencyBuilderProps {
    isEdit: boolean;
    value: CurrencyEnum;
    onChange: (values: CurrencyEnum) => void;
    allowedCurrencies: CurrencyArray;
}
export const CurrencyBuilder = ({
    isEdit,
    value,
    onChange,
    allowedCurrencies,
}: CurrencyBuilderProps) => {
    const currencyOptions = allowedCurrencies.map(createCurrencyOption);

    useEffect(() => {
        if (!isEdit && currencyOptions.length) {
            onChange(currencyOptions[0].value);
        }
    }, []);

    return (
        <InputWrapperFlex>
            <Typography.Text id="set-options-label">
                {t(`dynamic-components:manageField.fields.currencyField.setType`)}
            </Typography.Text>
            <FullWidthSelect
                aria-labelledby="select-currency"
                value={value}
                onChange={(currency) => onChange(currency as CurrencyEnum)}
                options={currencyOptions}
            />
        </InputWrapperFlex>
    );
};
