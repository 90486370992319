/* tslint:disable */
/**
 * This file is autogenerated using @sharefiledev/solution-view-engine-tools.
 * 
 * Run 'sve typegen <relative path to manifest file>'
 * 
 * Types are generated according to specification in a file that conforms to the UI Manifest schema:
 * https://us-solutionviewengine.sharefiletest.io/ui-manifest-v2.json
 */
import { PiletApi, PiralCustomExtensionSlotMap } from '@sharefiledev/sharefile-appshell';
import { BlockParams } from '@sharefiledev/solution-view-engine';

export interface ShellProps {
	piletApi: PiletApi;	
}
/** Block parameters for urn:sfblock:dynamic-components:dynamic-tables-content */

export interface DynamicTablesContentBlockParams {
  tableRID: string;
  readonly?: boolean;
  /**
   * Style of container
   */
  style?: {
    margin?: string;
  };
}
/** Block parameters for urn:sfblock:dynamic-components-pilet:dynamic-tables-with-tabs */

export interface DynamicTablesWithTabsBlockParams {
  containerRID: string;
}
/** Zone parameters for urn:sfzone:dynamic-components-pilet:dynamic-tables-with-tabs:tab */

export interface DynamicTablesWithTabsTabZoneParams {
  /**
   * Localized String Values
   */
  tabName?: {
    en?: string;
    es?: string;
    de?: string;
    fr?: string;
    it?: string;
    ja?: string;
    nl?: string;
    "pt-BR"?: string;
  };
}
/** Block parameters for urn:sfblock:dynamic-components:dynamic-tables-add-tab */

export interface DynamicTablesAddTabBlockParams {
  containerRID: string;
}
/** Block parameters for urn:block:dynamic-components:tables-provider */

/**
 * Will fetch tables by containerRID and provide them to the rest of the view. Will listen to an event urn:sfevent:dynamic-components:table-provider:add-table with payload of type { tableRID: string }.
 */
export interface TablesProviderBlockParams {
  /**
   * The container RID for finding all tables in a resource.
   */
  containerRID: string;
  /**
   * Whether or not to fetch the contents of table or just the list of tables.
   */
  includeTableContent?: boolean;
}
/** Zone parameters for urn:zone:dynamic-components:tables-provider:children */

export interface TablesProviderChildrenZoneParams {}
/** Contract Data for urn:zone:dynamic-components:tables-provider:children */

export interface TablesProviderChildrenContractData {
  tables?: LoadedTableSchema[];
  canAddTable?: boolean;
}
export interface LoadedTableSchema {
  tableRID?: string;
  tableName?: string;
  canEditTable?: boolean;
  canDeleteTable?: boolean;
  canManageUsers?: boolean;
  canSaveAsTemplate?: boolean;
  canExportTable?: boolean;
}

export namespace BlockNames {
	export const DynamicTablesContent = 'urn:sfblock:dynamic-components:dynamic-tables-content' as const;
	export type DynamicTablesContent = 'urn:sfblock:dynamic-components:dynamic-tables-content';
	export const DynamicTablesWithTabs = 'urn:sfblock:dynamic-components-pilet:dynamic-tables-with-tabs' as const;
	export type DynamicTablesWithTabs = 'urn:sfblock:dynamic-components-pilet:dynamic-tables-with-tabs';
	export const DynamicTablesAddTab = 'urn:sfblock:dynamic-components:dynamic-tables-add-tab' as const;
	export type DynamicTablesAddTab = 'urn:sfblock:dynamic-components:dynamic-tables-add-tab';
	export const TablesProvider = 'urn:block:dynamic-components:tables-provider' as const;
	export type TablesProvider = 'urn:block:dynamic-components:tables-provider';
}

export namespace ZoneTypes {
	export const DynamicTablesWithTabsTab = 'urn:sfzone:dynamic-components-pilet:dynamic-tables-with-tabs:tab' as const;
	export type DynamicTablesWithTabsTab = 'urn:sfzone:dynamic-components-pilet:dynamic-tables-with-tabs:tab';
	export const TablesProviderChildren = 'urn:zone:dynamic-components:tables-provider:children' as const;
	export type TablesProviderChildren = 'urn:zone:dynamic-components:tables-provider:children';
}

export type DynamicTablesContentProps = ShellProps & BlockParams & DynamicTablesContentBlockParams;
export type DynamicTablesWithTabsProps = ShellProps & BlockParams & DynamicTablesWithTabsBlockParams;
export type DynamicTablesAddTabProps = ShellProps & BlockParams & DynamicTablesAddTabBlockParams;
export type TablesProviderProps = ShellProps & BlockParams & TablesProviderBlockParams;

declare module '@sharefiledev/sharefile-appshell' {
	interface PiralCustomExtensionSlotMap {
		['urn:sfblock:dynamic-components:dynamic-tables-content']: BlockParams & DynamicTablesContentBlockParams;
		['urn:sfblock:dynamic-components-pilet:dynamic-tables-with-tabs']: BlockParams & DynamicTablesWithTabsBlockParams;
		['urn:sfblock:dynamic-components:dynamic-tables-add-tab']: BlockParams & DynamicTablesAddTabBlockParams;
		['urn:block:dynamic-components:tables-provider']: BlockParams & TablesProviderBlockParams;
	}
}

declare module '@sharefiledev/solution-view-engine' {
	interface BlockCustomExtensionSlotMap extends PiralCustomExtensionSlotMap {}

	interface PiletExtensionApi extends PiletApi {}
	
	interface ViewEngineCustomZoneParametersMap {
		['urn:sfzone:dynamic-components-pilet:dynamic-tables-with-tabs:tab']: DynamicTablesWithTabsTabZoneParams;
		['urn:zone:dynamic-components:tables-provider:children']: TablesProviderChildrenZoneParams;
	}

	interface ViewEngineCustomZoneContractDataMap {
		['urn:sfzone:dynamic-components-pilet:dynamic-tables-with-tabs:tab']: never;
		['urn:zone:dynamic-components:tables-provider:children']: TablesProviderChildrenContractData;
	}
}
