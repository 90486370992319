import {OverlayModalComponentProps} from '@sharefiledev/sharefile-appshell';
import {Skeleton} from 'antd';
import {lazy, Suspense} from 'react';

import {NotificationProvider} from '../utils/notification/useNotification';
import {RequestRecordViewProps} from './Overlays';

const LazyCreateTableModal = lazy(() => import('../overlays/create-table/CreateTableModal'));
const LazyEditTableModal = lazy(() => import('../overlays/edit-table/EditTableModal'));
const LazyDeleteTableModal = lazy(() => import('../overlays/delete-table/DeleteTableModal'));
const LazyExportTableModal = lazy(() => import('../overlays/export-table/ExportTableModal'));

export const AsyncLazyCreateTableModal: React.FC<
    OverlayModalComponentProps<'urn:sfmodal:dc-pilet:createtable'>
> = (props) => (
    <Suspense fallback={<Skeleton />}>
        <NotificationProvider>
            <LazyCreateTableModal {...props} />
        </NotificationProvider>
    </Suspense>
);

const LazyRequestViewDrawer = lazy(() => import('../overlays/request-view/RequestView'));
export const AsyncLazyRecordViewDrawer = (props: RequestRecordViewProps) => (
    <Suspense fallback={<Skeleton />}>
        <NotificationProvider>
            <LazyRequestViewDrawer {...props} />
        </NotificationProvider>
    </Suspense>
);

const LazyRequestClientViewDrawer = lazy(() => import('../overlays/client-view/ClientView'));
export const AsyncLazyClientViewDrawer = (props: RequestRecordViewProps) => (
    <Suspense fallback={<Skeleton />}>
        <NotificationProvider>
            <LazyRequestClientViewDrawer {...props} />
        </NotificationProvider>
    </Suspense>
);

export const AsyncLazyEditTableModal: React.FC<
    OverlayModalComponentProps<'urn:sfmodal:dc-pilet:edittable'>
> = (props) => (
    <Suspense fallback={<Skeleton />}>
        <NotificationProvider>
            <LazyEditTableModal {...props} />
        </NotificationProvider>
    </Suspense>
);

export const AsyncLazyDeleteTableModal: React.FC<
    OverlayModalComponentProps<'urn:sfmodal:dc-pilet:deletetable'>
> = (props) => (
    <Suspense fallback={<Skeleton />}>
        <NotificationProvider>
            <LazyDeleteTableModal {...props} />
        </NotificationProvider>
    </Suspense>
);

export const AsyncLazyExportTableModal: React.FC<
    OverlayModalComponentProps<'urn:sfmodal:dc-pilet:exporttable'>
> = (props) => (
    <Suspense fallback={<Skeleton />}>
        <NotificationProvider>
            <LazyExportTableModal {...props} />
        </NotificationProvider>
    </Suspense>
);
