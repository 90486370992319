import {Typography} from 'antd';

// import {FieldProperties} from '../../../../engine/types';
import {InputFieldProps} from '../Fields';
import {isNullOrUndefined} from '../utils';

const {Text} = Typography;

const convertToTimeFormat = (minutes: number) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    const formattedTime = `${String(hours).padStart(2, '0')}:${String(remainingMinutes).padStart(
        2,
        '0'
    )}`;
    return formattedTime;
};
export const DurationViewField = ({fieldValue}: InputFieldProps) => {
    if (isNullOrUndefined(fieldValue) || typeof fieldValue === 'string') {
        return null;
    }
    const formattedDate = convertToTimeFormat(Number(fieldValue));
    return <Text ellipsis={{tooltip: `${formattedDate}`}}>{formattedDate}</Text>;
};
