import {Badge} from 'antd';

import {COLORS} from '../../../constants/constants';
import {FieldsEnum} from '../../../constants/fields';
import {SelectFieldProps} from '../Fields';
import {StyledTag} from '../Fields.styled';

export const SingleSelectText = ({attributes, fieldValue}: SelectFieldProps) => {
    const selectConfig = attributes.enumConfig;
    const activeStatus = fieldValue;
    const isStatus = attributes?.subType === FieldsEnum.Status;
    const getBadge = (badgeType: string, children: React.ReactNode) => {
        if (badgeType) {
            return (
                <StyledTag
                    icon={isStatus ? <Badge color={COLORS[badgeType].color} /> : null}
                    bordered={isStatus}
                    badgeType={badgeType}
                    isStatus={isStatus}
                >
                    {children}
                </StyledTag>
            );
        }
        return null;
    };
    const currentSelectConfig = selectConfig![activeStatus];

    if (!currentSelectConfig) return null;

    return <>{getBadge(currentSelectConfig.badgeType, currentSelectConfig.displayName)}</>;
};
