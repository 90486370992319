import {Avatar, Col, Row, Typography} from 'antd';

import {LicenseType} from '../../../enum';
import {ContactOptionProps} from '../Fields';
import {StyledUserType} from '../Fields.styled';
export const ContactOption = (contact: ContactOptionProps) => {
    const {roleMapper = {}} = contact;

    const getInitials = (firstName: string, lastName: string) => {
        let initials = firstName.substring(0, 1).toLocaleUpperCase();
        lastName ? (initials += lastName.substring(0, 1).toLocaleUpperCase()) : (initials += '');
        return initials;
    };

    const userType = contact.userType;
    const fetchUserType = LicenseType[userType as keyof typeof LicenseType] ?? userType;
    const userRole = Object.keys(roleMapper).find(
        (key) => key.toLowerCase() === fetchUserType.toLowerCase()
    );

    return (
        <Row justify="space-between" gutter={14} wrap={false}>
            <Col flex="none">
                <Avatar size={28}>{getInitials(contact.firstName, contact.lastName)}</Avatar>
            </Col>
            <Col flex="auto">
                <div>
                    <Typography.Text
                        strong
                        ellipsis
                    >{`${contact.firstName} ${contact.lastName}`}</Typography.Text>
                </div>
                <div>
                    <Typography.Text ellipsis type="secondary" style={{fontSize: '12px'}}>
                        {contact.email}
                    </Typography.Text>
                </div>
            </Col>
            {contact.userType && (
                <Col flex="none">
                    <StyledUserType>
                        {userRole ? roleMapper[userRole as keyof typeof roleMapper] : fetchUserType}
                    </StyledUserType>
                </Col>
            )}
        </Row>
    );
};
