import styled from '@emotion/styled';
import {colorPalette, sizes} from '@sharefiledev/antd-config';
import {ChevronDown} from '@sharefiledev/icons';
import {Flex, Select} from 'antd';

export const IconStyle = styled.div`
    margin-right: ${sizes.XS};
    display: flex;
    align-items: center;
`;

export const LabelStyle = styled.span`
    display: flex;
    align-items: center;
`;

export const StyledChevronDown = styled(ChevronDown)`
    color: ${colorPalette.neutral6};
`;

export const FullWidthSelect = styled(Select)`
    width: 100%;
`;

export const ContainerFlex = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: ${sizes.base};
`;

export const InputWrapperFlex = styled(Flex)`
    display: flex;
    flex-direction: column;
    padding: ${sizes.XXS};
    width: 48%;
    gap: ${sizes.XXS};
`;
