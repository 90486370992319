import {DateFormatEnum} from '../../../constants/fields';
import {t} from '../../../utils';
import {DateBuilderConfigurationType} from '../DataTable';

export const DateBuilderConfiguration: DateBuilderConfigurationType = {
    [DateFormatEnum.Date]: {
        label: t('dynamic-components:manageField.fields.dateOverdue.dateFormat.options.Date'),
        value: DateFormatEnum.Date,
    },
    // [DateFormatEnum.Time]: {
    //     label: t('dynamic-components:manageField.fields.dateOverdue.dateFormat.options.Time'),
    //     value: DateFormatEnum.Time,
    //     timeFormatRequired: true,
    // },
    [DateFormatEnum.DateTime]: {
        label: t('dynamic-components:manageField.fields.dateOverdue.dateFormat.options.DateTime'),
        value: DateFormatEnum.DateTime,
    },
    [DateFormatEnum.DateRange]: {
        label: t('dynamic-components:manageField.fields.dateOverdue.dateFormat.options.DateRange'),
        value: DateFormatEnum.DateRange,
    },
};
