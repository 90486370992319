import {DatePicker} from 'antd';
import dayjs from 'dayjs';
import {memo, useMemo} from 'react';

import {DEFAULT_DATE_FORMAT, SECONDS_PATTERN} from '../../constants/constants';
import {DateFormatEnum} from '../../constants/fields';
import {NullableDayjsType} from '../../layouts/Fields/Fields';
import {isDateTimeFormat, parseDateFormats} from '../../layouts/Fields/utils';
import {convertUtcToLocal, getAdjustedTimestampFromDayjs} from '../../utils/utils';
import {StyledPanel} from './DateRangePicker.styled';

const {RangePicker} = DatePicker;

type DateRangePickerProps = {
    value: [number, number] | [];
    onChange: (e: {value: [number, number] | []; errorMessage: string}) => void;
    ariaLabel?: string;
    dateFormat?: string;
    forwardedRef: React.RefObject<any>;
    timeZoneOffsetMins?: number;
};

export const DateRangePicker = memo(
    ({
        onChange,
        value,
        ariaLabel,
        dateFormat,
        forwardedRef,
        timeZoneOffsetMins,
    }: DateRangePickerProps) => {
        const isDateTimeField = isDateTimeFormat(dateFormat);

        const DATE_FORMAT = parseDateFormats(
            !dateFormat || dateFormat === DateFormatEnum.DateRange
                ? DEFAULT_DATE_FORMAT
                : dateFormat,
            SECONDS_PATTERN,
            true
        );
        const values: [start: NullableDayjsType, end: NullableDayjsType] = useMemo(() => {
            if (value && value.length === 2) {
                const offsetFromUTCStart = dayjs(value[0] * 1000).utcOffset();
                const offsetFromUTCEnd = dayjs(value[1] * 1000).utcOffset();
                return [
                    dayjs(
                        convertUtcToLocal(
                            (value[0] - offsetFromUTCStart * 60) * 1000,
                            timeZoneOffsetMins
                        )
                    ),
                    dayjs(
                        convertUtcToLocal(
                            (value[1] - offsetFromUTCEnd * 60) * 1000,
                            timeZoneOffsetMins
                        )
                    ),
                ];
            } else {
                return [null, null];
            }
        }, [value]);

        const onDateChange = (dates: [NullableDayjsType, NullableDayjsType] | null) => {
            if (dates && dates[0] && dates[1]) {
                const [startDateLocal, endDateLocal] = dates;
                const startDate = getAdjustedTimestampFromDayjs(startDateLocal, timeZoneOffsetMins);
                const endDate = getAdjustedTimestampFromDayjs(endDateLocal, timeZoneOffsetMins);
                onChange({
                    value: [startDate, endDate],
                    errorMessage: '',
                });
            } else {
                onChange({value: [], errorMessage: ''});
            }
        };

        return (
            <RangePicker
                showTime={isDateTimeField}
                id={isDateTimeField ? 'date-time-range-picker' : 'date-range-picker'}
                value={values}
                panelRender={(originalPanel) => {
                    return <StyledPanel>{originalPanel}</StyledPanel>;
                }}
                onChange={onDateChange}
                style={{width: '100%'}}
                ref={forwardedRef}
                aria-label={`${ariaLabel} ${isDateTimeField ? 'date-time-picker' : 'date-picker'}`}
                format={DATE_FORMAT}
            />
        );
    }
);
