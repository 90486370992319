import {t} from '../../../../utils/translate';
import {TableAction} from '..';

export const ColumnOptions = (
    name: string,
    component: string,
    columnIndex: number,
    totalColumns: number,
    canDeleteColumn: boolean,
    isTemplateField: boolean,
    nonSortableColumns: string[],
    canEditColumn?: boolean
) => {
    // Initialize items array
    const items: any[] = [];
    const moveLeft = columnIndex > 1;
    const moveRight = columnIndex > 0 && columnIndex < totalColumns - 1;

    // Add edit field option if allowed
    if (canEditColumn) {
        items.push(
            {
                label: t('dynamic-components:dropdownColumnOptions.editField'),
                key: `edit_${name}`,
            },
            {type: 'divider'}
        );
    }

    // Add sort options if the field is sortable
    if (!nonSortableColumns.includes(component)) {
        items.push(
            {
                label: t('dynamic-components:dropdownColumnOptions.sortAscending'),
                key: `asc_${name}`,
            },
            {
                label: t('dynamic-components:dropdownColumnOptions.sortDescending'),
                key: `desc_${name}`,
            }
        );
    }

    if (moveLeft || moveRight) {
        items.push({
            type: 'divider',
        });

        if (moveLeft) {
            items.push({
                label: t('dynamic-components:dropdownColumnOptions.moveLeft'),
                key: TableAction.MOVE_LEFT + `_${name}`,
            });
        }
        if (moveRight) {
            items.push({
                label: t('dynamic-components:dropdownColumnOptions.moveRight'),
                key: TableAction.MOVE_RIGHT + `_${name}`,
            });
        }
    }

    // Add remove field option if not the first column
    if (columnIndex !== 0 && canDeleteColumn && canEditColumn && !isTemplateField) {
        items.push(
            {type: 'divider'},
            {
                label: t('dynamic-components:dropdownColumnOptions.removeField'),
                danger: true,
                key: `remove_${name}`,
            }
        );
    }

    return items;
};
