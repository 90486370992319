import {Button, Col, Input, Row} from 'antd';
import {useMemo, useState} from 'react';

import {ColorPicker} from '../../../../common/color-picker/ColorPicker';
import {t} from '../../../../utils';
import {Icons} from '../../../../utils/icons/icon';
import {ColumnDefinition, isManageColumnValid} from '../../DataTable';
import {
    AddOptionButton,
    AriaLive,
    SelectFieldOptions,
    SetOptionsLabel,
} from './SelectFieldBuilder.styled';

// only enum, enum config should be passed as a prop
// check if enum and enum config are redundant
interface SelectFieldBuilderProps {
    editColumnSchema?: ColumnDefinition;
    isValid: isManageColumnValid;
    setIsValid: (value: isManageColumnValid) => void;
    addColumnFormValues: ColumnDefinition;
    setAddColumnFormValues: (values: ColumnDefinition) => void;
}
export const SelectFieldBuilder = ({
    editColumnSchema,
    isValid,
    setIsValid,
    addColumnFormValues,
    setAddColumnFormValues,
}: SelectFieldBuilderProps) => {
    const [ariaLiveRegionMessage, setAriaLiveMessage] = useState('');

    // memoise previous values so that on add existingEnums dont get updated.
    const existingEnums = useMemo(() => [...addColumnFormValues.attributes.enum], []);

    const errorMessageFn = (index: number, option: any): string => {
        const enumList = [
            ...addColumnFormValues.attributes.enum.map((enumValue: string) => {
                return enumValue.trim();
            }),
        ];
        if (
            enumList.indexOf(option.trim()) == enumList.lastIndexOf(option.trim()) ||
            index == enumList.indexOf(option.trim())
        )
            return '';
        return 'error';
    };

    const validateOptions = (enumList: string[]): boolean => {
        const trimmedList = enumList.map((enumValue) => enumValue.trim());

        const hasDuplicates = trimmedList.some(
            (value, index) => trimmedList.indexOf(value) !== index && !!enumConfig[value]
        );

        return !hasDuplicates;
    };

    const onEnumEdit = (e: any, index: number) => {
        const value = e.target.value;
        const enumList = [...addColumnFormValues.attributes.enum];
        const enumConfig = {...addColumnFormValues.attributes.enumConfig};

        const prevEnumConfig = enumConfig[enumList[index]];

        // delete enum from set if there are no duplicates
        enumList.indexOf(enumList[index]) === enumList.lastIndexOf(enumList[index])
            ? delete enumConfig[enumList[index]]
            : enumConfig;

        enumList[index] = value;
        enumConfig[value] = {...prevEnumConfig, displayName: value};

        enumList[index] = value;

        setAddColumnFormValues({
            ...addColumnFormValues,
            attributes: {
                ...addColumnFormValues.attributes,
                enum: enumList,
                enumConfig: enumConfig,
            },
        });
        setIsValid({...isValid, fieldBuilder: validateOptions(enumList)});
    };

    const onLabelEdit = (e: any, index: number) => {
        const value = e.target.value;
        const enumList = [...addColumnFormValues.attributes.enum];
        const enumConfig = {...addColumnFormValues.attributes.enumConfig};

        const prevEnumConfig = enumConfig[enumList[index]];

        // if enums already same as soft deleted
        if (enumList.indexOf(value) !== -1 && enumConfig[value].isDeleted) {
            const index = enumList.indexOf(value);
            enumList.splice(index, 1);
        }

        // update enum Label if it already exists or add new One
        if (existingEnums.indexOf(enumList[index]) === index) {
            enumConfig[enumList[index]] = {...prevEnumConfig, displayName: value};
        } else {
            enumList.indexOf(enumList[index]) === enumList.lastIndexOf(enumList[index])
                ? delete enumConfig[enumList[index]]
                : enumConfig;

            enumList[index] = value;
            enumConfig[value] = {...prevEnumConfig, displayName: value};
        }

        setAddColumnFormValues({
            ...addColumnFormValues,
            attributes: {
                ...addColumnFormValues.attributes,
                enum: enumList,
                enumConfig: enumConfig,
            },
        });
        setIsValid({...isValid, fieldBuilder: validateOptions(enumList)});
    };

    const onCancel = (index: number) => {
        const enumList = [...addColumnFormValues.attributes.enum];
        const enumConfig = {...addColumnFormValues.attributes.enumConfig};

        // atleast one options should be there.
        const count = enumList.filter((option: string) => !enumConfig[option].isDeleted).length;
        if (count > 1) {
            // soft delete if its exsiting enum
            if (existingEnums.includes(enumList[index])) {
                const updatedEnumConfig = {...enumConfig[enumList[index]], isDeleted: true};
                enumConfig[enumList[index]] = updatedEnumConfig;

                // move it to back of the array so that index doesnt get affect for non deleted enums
                const deletedEnum = enumList.splice(index, 1);
                enumList.push(deletedEnum[0]);
            } else {
                const deletedEnum = enumList.splice(index, 1);
                // check for duplicate delete config only if its not duplicate
                enumList.indexOf(deletedEnum[0]) === -1
                    ? delete enumConfig[deletedEnum[0]]
                    : enumConfig;
            }
        }

        setAddColumnFormValues({
            ...addColumnFormValues,
            attributes: {
                ...addColumnFormValues.attributes,
                enum: enumList,
                enumConfig: enumConfig,
            },
        });
        setIsValid({...isValid, fieldBuilder: validateOptions(enumList)});
    };

    const onSelect = (value: string, index: number) => {
        const enumList = [...addColumnFormValues.attributes.enum];
        const enumConfig = {...addColumnFormValues.attributes.enumConfig};

        const updatedEnum = enumConfig[enumList[index]];

        enumConfig[enumList[index]] = {...updatedEnum, badgeType: value};

        setAddColumnFormValues({
            ...addColumnFormValues,
            attributes: {
                ...addColumnFormValues.attributes,
                enum: enumList,
                enumConfig: enumConfig,
            },
        });
        setIsValid({...isValid, fieldBuilder: validateOptions(enumList)});
    };

    const onAdd = () => {
        const enumList = addColumnFormValues.attributes.enum;
        const enumConfig = {...addColumnFormValues.attributes.enumConfig};

        const uniqueId = Date.now();
        const newOption = `${uniqueId}`;
        enumConfig[newOption] = {displayName: '', badgeType: 'default'};

        // deleted enums should always be at the back, so new enums should be inserted before deleted enums
        let indexOfInsertion = 0;
        while (indexOfInsertion < enumList.length) {
            if (enumConfig[enumList[indexOfInsertion]].isDeleted ?? false) {
                break;
            }
            indexOfInsertion += 1;
        }

        const updatedEnumList = [
            ...enumList.slice(0, indexOfInsertion),
            newOption,
            ...enumList.slice(indexOfInsertion),
        ];

        setAddColumnFormValues({
            ...addColumnFormValues,
            attributes: {
                ...addColumnFormValues.attributes,
                enum: updatedEnumList,
                enumConfig: enumConfig,
            },
        });
        setIsValid({...isValid, fieldBuilder: validateOptions(updatedEnumList)});

        // Update the message and key together to force re-render of live region
        setAriaLiveMessage(
            t('dynamic-components:manageField.fields.singleSelect.optionAdded') +
                ' ' +
                (indexOfInsertion + 1)
        );
    };

    const buildAriaLabelForRemoveBtn = (inputValue: string, index: number) => {
        const label = inputValue
            ? t(
                  'dynamic-components:manageField.fields.singleSelect.removeBtnAriaLabel.fieldWithValue',
                  {labelName: inputValue}
              )
            : t(
                  'dynamic-components:manageField.fields.singleSelect.removeBtnAriaLabel.emptyField',
                  {labelIndex: index + 1}
              );
        return label;
    };

    const optionsList = addColumnFormValues.attributes.enum;
    const enumConfig = addColumnFormValues.attributes.enumConfig;
    return (
        <>
            {/* ARIA live region */}
            <AriaLive aria-live="polite" aria-atomic="true">
                {ariaLiveRegionMessage}
            </AriaLive>
            <SetOptionsLabel id="set-options-label">
                {t('dynamic-components:manageField.fields.singleSelect.setOptions')}
            </SetOptionsLabel>
            <SelectFieldOptions data-testid="select-field-builder">
                {optionsList
                    .filter((option: string) => !enumConfig[option].isDeleted)
                    .map((option: string, index: number) => {
                        return (
                            <Row
                                gutter={[16, 24]}
                                key={index}
                                style={{display: 'flex', alignItems: 'center', marginBottom: '4px'}}
                            >
                                <Col className="gutter-row" span={2}>
                                    <ColorPicker
                                        value={enumConfig[option].badgeType}
                                        onSelect={(value: string) => onSelect(value, index)}
                                    />
                                </Col>
                                <Col
                                    className="gutter-row"
                                    style={{paddingLeft: '2px', paddingRight: '2px'}}
                                    span={20}
                                >
                                    <Input
                                        value={enumConfig[option].displayName}
                                        id={`${index}`}
                                        aria-labelledby="set-options-label"
                                        placeholder={t(
                                            'dynamic-components:manageField.fields.singleSelect.placeholder'
                                        )}
                                        onChange={(e: any) =>
                                            editColumnSchema
                                                ? onLabelEdit(e, index)
                                                : onEnumEdit(e, index)
                                        }
                                        status={
                                            errorMessageFn(index, option) as
                                                | ''
                                                | 'error'
                                                | 'warning'
                                        }
                                        style={
                                            errorMessageFn(index, option) ? {} : {border: 'none'}
                                        }
                                        className="select-field-option"
                                    />
                                </Col>
                                {!addColumnFormValues?.isTemplateField && (
                                    <Col className="gutter-row" span={2}>
                                        <Button
                                            aria-label={buildAriaLabelForRemoveBtn(
                                                enumConfig[option].displayName,
                                                index
                                            )}
                                            icon={<Icons.Close />}
                                            onClick={() => onCancel(index)}
                                            key={index}
                                            shape="circle"
                                            type="text"
                                        />
                                    </Col>
                                )}
                            </Row>
                        );
                    })}
            </SelectFieldOptions>
            <AddOptionButton
                onClick={onAdd}
                type="text"
                icon={<Icons.Add size={18} />}
                data-testid="add-select-option"
            >
                {t('dynamic-components:manageField.fields.singleSelect.addOption')}
            </AddOptionButton>
        </>
    );
};
