import {Spin} from 'antd';
import React from 'react';

import {LocalizationLoader, t} from './translate';

interface Props {
    localizationKey: string;
}

export const TranslatedString: React.FC<Props> = ({localizationKey}) => {
    return (
        <LocalizationLoader loadingElement={<Spin />}>
            {() => <>{t(localizationKey)}</>}
        </LocalizationLoader>
    );
};
