import dayjs from 'dayjs';
import {useMemo, useRef, useState} from 'react';

import {DATE_RANGE_REGEX, DEFAULT_DATE_FORMAT} from '../../../constants/constants';
import {RangePickerState} from '../../../constants/fields';
import {convertUtcToLocal, getAdjustedTimestampFromDayjs} from '../../../utils/utils';
import {DateFieldProps, InputType, NullableDayjsType} from '../Fields';
import {StyledRangePicker} from '../Fields.styled';
import {useFieldOperations} from '../hooks/useFieldOperations';
import {handleDisabledDate, parseDateFormats} from '../utils';

export const DateRangePickerField = ({
    dateFormat,
    disabled,
    fieldValue,
    required,
    validations,
    forwardedRef,
    onChange,
    onCancel,
    className,
    dataTestId,
    timeZoneOffsetMins,
    fieldType,
}: DateFieldProps) => {
    const DATE_FORMAT = parseDateFormats(dateFormat, DATE_RANGE_REGEX) ?? DEFAULT_DATE_FORMAT;
    const hasDateChangedRef = useRef<boolean>(false);
    const [isFirstInputFocused, setIsFirstInputFocused] = useState(false);

    const handleFocus = (range: string | undefined) => {
        setIsFirstInputFocused(range === RangePickerState.start);
    };
    const {inputValue, handleOnChange, isError, handleOnBlur, handleKeyDown} =
        useFieldOperations<InputType>(
            fieldType,
            fieldValue,
            required,
            validations,
            onChange,
            onCancel
        );
    const [isOpen, setIsOpen] = useState(false);
    const handleBlur = () => {
        if (!isFirstInputFocused || values[0] === null) {
            handleOnBlur();
        }
        return;
    };

    const values: [start: NullableDayjsType, end: NullableDayjsType] = useMemo(() => {
        if (
            Array.isArray(inputValue) &&
            inputValue.length === 2 &&
            inputValue.every((item) => typeof item === 'number')
        ) {
            const offsetFromUTCStart = dayjs(inputValue[0] * 1000).utcOffset();
            const offsetFromUTCEnd = dayjs(inputValue[1] * 1000).utcOffset();
            return [
                dayjs(
                    convertUtcToLocal(
                        (inputValue[0] - offsetFromUTCStart * 60) * 1000,
                        timeZoneOffsetMins
                    )
                ),
                dayjs(
                    convertUtcToLocal(
                        (inputValue[1] - offsetFromUTCEnd * 60) * 1000,
                        timeZoneOffsetMins
                    )
                ),
            ];
        } else {
            return [null, null];
        }
    }, [inputValue]);

    const onDateChange = (
        dates: [NullableDayjsType, NullableDayjsType] | null,
        isCalenderChange = false
    ) => {
        !isCalenderChange && (hasDateChangedRef.current = true);
        if (dates && dates[0] && dates[1]) {
            const [startDateLocal, endDateLocal] = dates;
            const startDate = getAdjustedTimestampFromDayjs(startDateLocal, timeZoneOffsetMins);
            const endDate = getAdjustedTimestampFromDayjs(endDateLocal, timeZoneOffsetMins);
            handleOnChange([startDate, endDate]);
            !isCalenderChange && onChange([startDate, endDate], !isError);
        } else if (!dates) {
            handleOnChange(dates);
        }
    };
    const handleOnOpenChange = (open: boolean) => {
        if (!open && isOpen) {
            !hasDateChangedRef.current && handleOnBlur();
        }
        setIsOpen(open);
    };
    const handleOnKeyDown = (event: React.KeyboardEvent<any>) => {
        if (isOpen && event.key === 'Escape') {
            event.stopPropagation();
            onCancel(true);
        }
        if (event.key === 'Tab' && isFirstInputFocused && !event.shiftKey) {
            event.stopPropagation();
            return;
        }
        !isOpen && handleKeyDown(event);
    };

    return (
        <StyledRangePicker
            className={className}
            value={values}
            format={DATE_FORMAT}
            autoFocus
            ref={forwardedRef}
            disabled={disabled}
            data-testid={dataTestId}
            onBlur={isOpen ? undefined : handleBlur}
            onChange={(dates) => onDateChange(dates)}
            onCalendarChange={(dates) => onDateChange(dates, true)}
            disabledDate={(current) => handleDisabledDate(current, timeZoneOffsetMins, validations)}
            onOpenChange={handleOnOpenChange}
            onFocus={(_, {range}) => handleFocus(range)}
            onKeyDown={handleOnKeyDown}
        />
    );
};
