import {Radio} from 'antd';
import {useEffect} from 'react';

import {DateFormatEnum} from '../../../../../constants/fields';
import {t} from '../../../../../utils';
import {DateBuilderConfiguration} from '../../../configurations';
import {DateBuilderProps} from '../../../DataTable';
import {
    DateContainer,
    StyledLabel,
    StyledSelect,
    StyleTimeLabel,
    TimeContainer,
} from './DateBuilder.styled';

const createDateOverdueOption = (dateFormat: DateFormatEnum) => ({
    label: (
        <span data-testid={DateBuilderConfiguration[dateFormat]?.value ?? dateFormat}>
            {DateBuilderConfiguration[dateFormat]?.label ?? dateFormat}
        </span>
    ),
    value: DateBuilderConfiguration[dateFormat]?.value ?? dateFormat,
});
const getTimeFormat = (format: string): boolean | undefined => {
    if (/HH:mm:ss/.test(format)) return true; // 24-hour format
    if (/hh:mm:ss/.test(format)) return false; // 12-hour format
    return undefined; // No time format present
};

const getSelectedValue = (format: string, is24HourFormat?: boolean): string => {
    if (is24HourFormat === false) {
        return format.replace(/hh:mm:ss A/, 'HH:mm:ss'); // Convert 12-hour to 24-hour
    }
    return format; // Keep the format unchanged otherwise
};

export const DateBuilder = ({isEdit, value, onChange, allowedDateFormat}: DateBuilderProps) => {
    const dateOverDueOptions = allowedDateFormat.map(createDateOverdueOption);
    const is24HourFormat = getTimeFormat(value);
    const selectedValue = getSelectedValue(value, is24HourFormat);

    useEffect(() => {
        if (!isEdit && dateOverDueOptions.length) {
            onChange(dateOverDueOptions[0].value);
        }
    }, []);

    const handleTimeFormatChange = (is24HourFormat: boolean) => {
        const finalValue =
            is24HourFormat === false
                ? selectedValue.replace(/HH:mm:ss/, 'hh:mm:ss A')
                : selectedValue;
        onChange(finalValue);
    };

    return (
        <>
            <DateContainer>
                <StyledLabel id="set-options-label">
                    {t(`dynamic-components:manageField.fields.dateOverdue.dateFormat.label`)}
                </StyledLabel>
                <StyledSelect
                    aria-labelledby="Select Date Format"
                    value={selectedValue}
                    onChange={(value) => onChange(value as string)}
                    options={dateOverDueOptions}
                    disabled={isEdit}
                    data-testid="add-date-select"
                />
            </DateContainer>
            {is24HourFormat != undefined && (
                <TimeContainer>
                    <StyleTimeLabel id="set-options-label">
                        {t(`dynamic-components:manageField.fields.dateOverdue.timeFormat.label`)}
                    </StyleTimeLabel>
                    <Radio.Group
                        value={is24HourFormat}
                        onChange={(e) => handleTimeFormatChange(e.target.value)}
                    >
                        <Radio value>
                            {t(
                                `dynamic-components:manageField.fields.dateOverdue.timeFormat.options.24h`
                            )}
                        </Radio>
                        <Radio value={false}>
                            {t(
                                `dynamic-components:manageField.fields.dateOverdue.timeFormat.options.12h`
                            )}
                        </Radio>
                    </Radio.Group>
                </TimeContainer>
            )}
        </>
    );
};
