import {PiletApi} from '@sharefiledev/sharefile-appshell';
import {Skeleton} from 'antd';
import {lazy, Suspense} from 'react';

import {BlockNames} from './block.types';

export const addBlocks = (piletApi: PiletApi) => {
    const LazyDynamicTablesWithTabsDevelopment = lazy(() => import('./DynamicTablesWithTabs'));
    piletApi.registerExtension(BlockNames.DynamicTablesWithTabs, (props) => (
        <Suspense fallback={<Skeleton />}>
            <LazyDynamicTablesWithTabsDevelopment {...props} />
        </Suspense>
    ));

    const LazyDynamicTable = lazy(() => import('./DynamicTableBlock'));
    piletApi.registerExtension(BlockNames.DynamicTablesContent, (props) => (
        <Suspense fallback={<Skeleton />}>
            <LazyDynamicTable {...props} />
        </Suspense>
    ));

    const LazyAddTab = lazy(() => import('./AddTabBlock'));
    piletApi.registerExtension(BlockNames.DynamicTablesAddTab, (props) => (
        <Suspense fallback={<Skeleton />}>
            <LazyAddTab {...props} />
        </Suspense>
    ));

    const LazyTableProviderBlock = lazy(() => import('./TablesProviderBlock'));
    piletApi.registerExtension(BlockNames.TablesProvider, (props) => (
        <Suspense fallback={<Skeleton />}>
            <LazyTableProviderBlock {...props} />
        </Suspense>
    ));
};
