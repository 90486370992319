import dayjs from 'dayjs';
import {isNumber} from 'lodash';

import {DateTimeFormatEnum} from '../../../constants/fields';
import {t} from '../../../utils';
import {convertUtcToLocal} from '../../../utils/utils';
import {DateFieldProps, InputType} from '../Fields';

export const stringToArray = (fieldValue: string | string[] | null): string[] => {
    if (!fieldValue || (Array.isArray(fieldValue) && fieldValue.some((item) => item === null))) {
        return [];
    }
    return Array.isArray(fieldValue) ? fieldValue : [fieldValue];
};

const validate = (inputValue: InputType, required: boolean, validations: any) => {
    if (required && (!inputValue || (Array.isArray(inputValue) && inputValue.length === 0))) {
        return t('dynamic-components:validations.required');
    }
    if (typeof inputValue === 'number') return '';
    if (validations?.minLength && inputValue && inputValue.length < validations?.minLength.value) {
        return validations?.minLength.message;
    } else if (
        validations?.maxLength &&
        inputValue &&
        inputValue.length > validations?.maxLength.value
    ) {
        return validations?.maxLength.message;
    } else {
        return '';
    }
};

export const validateInput = (value: InputType, required: boolean, validations: any) => {
    const errorMessage = required && validate(value, required, validations);
    return errorMessage ?? '';
};
export const handleDisabledDate = (
    current: dayjs.Dayjs,
    timeZoneOffsetMins: number | undefined,
    validations?: DateFieldProps['validations']
): boolean => {
    if (!current) {
        return false;
    }
    const localDateTime = convertUtcToLocal(undefined, timeZoneOffsetMins);
    const currentTimestamp = current.unix();
    const minDate = typeof validations?.minDate === 'number' && dayjs.unix(validations.minDate);
    const maxDate = typeof validations?.maxDate === 'number' && dayjs.unix(validations.maxDate);
    const {pastDays, futureDays} = validations || {};

    // Calculate allowed date range based on pastDays and futureDays
    const allowedPastDays =
        isNumber(pastDays) &&
        dayjs(localDateTime)
            .subtract(Math.abs(pastDays) + 1, 'days')
            .unix();
    const allowedFutureDays =
        isNumber(futureDays) && dayjs(localDateTime).add(futureDays, 'days').unix();
    const isPastDateDisabled = allowedPastDays && currentTimestamp < allowedPastDays;
    const isFutureDateDisabled = allowedFutureDays && currentTimestamp > allowedFutureDays;
    const isBeforeMinDate = minDate && current.isBefore(minDate, 'day');
    const isAfterMaxDate = maxDate && current.isAfter(maxDate, 'day');

    // Disable dates outside the allowed range
    if (isBeforeMinDate || isAfterMaxDate || isPastDateDisabled || isFutureDateDisabled) {
        return true;
    }
    return false;
};
export const parseDateFormats = (
    input: string | undefined,
    substring: string,
    isSanitize = false
): string | undefined => {
    if (!input) return undefined;
    if (isSanitize) {
        const targetRegex = new RegExp(substring, 'g');
        return input.replace(targetRegex, '');
    }
    const regex = new RegExp(substring);
    const dateFormats = input.match(regex);
    if (dateFormats && dateFormats[1]) {
        return dateFormats[1];
    }
    return undefined;
};
export const isNullOrUndefined = (value: any): value is null | undefined => {
    if (value === null || value === undefined) {
        return true;
    }
    return false;
};
export const isDateTimeFormat = (format: string | undefined) => {
    return format === DateTimeFormatEnum.DateTime || format === DateTimeFormatEnum.DateTime12Hrs;
};
