import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import _ from 'lodash';
import {useCallback, useEffect, useState} from 'react';

import {useRichText} from '../../../table/context/RichText';
import {RTEProps} from '../../Fields';
import Editor from './Editor';
import {checkIfNode, createUniqueId, fetchCurrentField} from './helper';
import {MainContainer} from './SFLexicalRichTextEditor.styled';
import withRichTextEditor from './withRichTextEditor';

const SFLexicalRichText = (props: RTEProps) => {
    const [editor] = useLexicalComposerContext();
    const {fieldValue: value, name, isExpanded = false, record} = props;
    const {textEditors, reload, setReload} = useRichText();
    const [prevState, setPrevState] = useState<any>();

    const renderState = useCallback((editorValue: any) => {
        const editorState = editor.parseEditorState(JSON.stringify(checkIfNode(editorValue)));
        editor.setEditorState(editorState);
        editor.setEditable(!props.disabled);
    }, []);

    useEffect(() => {
        if (_.isEqual(prevState, value)) return;
        renderState(value);
        setPrevState(_.cloneDeep(value));
    }, [value, renderState]);

    useEffect(() => {
        if (!reload) return;
        const uniqueID = createUniqueId(name, record?._id);
        const currentField = fetchCurrentField(textEditors, uniqueID);
        renderState(currentField?.rtFieldValue);
        setReload(false);
    }, [reload]);

    return (
        <MainContainer id="editor-container" isFullscreen={isExpanded}>
            <Editor editorProp={props} />
        </MainContainer>
    );
};

const wrappedSFLexicalRichTextEditor = withRichTextEditor(SFLexicalRichText);
export default wrappedSFLexicalRichTextEditor;
