import {TRANSFORMERS} from '@lexical/markdown';
import {CheckListPlugin} from '@lexical/react/LexicalCheckListPlugin';
import {ContentEditable} from '@lexical/react/LexicalContentEditable';
import {LexicalErrorBoundary} from '@lexical/react/LexicalErrorBoundary';
import {HistoryPlugin} from '@lexical/react/LexicalHistoryPlugin';
import {LinkPlugin} from '@lexical/react/LexicalLinkPlugin';
import {ListPlugin} from '@lexical/react/LexicalListPlugin';
import {MarkdownShortcutPlugin} from '@lexical/react/LexicalMarkdownShortcutPlugin';
import {RichTextPlugin} from '@lexical/react/LexicalRichTextPlugin';
import {TabIndentationPlugin} from '@lexical/react/LexicalTabIndentationPlugin';

import {t} from '../../../../utils';
import {RTEProps} from '../../Fields';
import PlaygroundAutoLinkPlugin from './plugIns/AutoLink';
import CodeHighlightPlugin from './plugIns/CodeHighlight';
import ToolbarPlugin from './plugIns/EditorToolbar';
import Footer from './plugIns/Footer';
import {OnChangePlugin} from './plugIns/OnChange';

const Placeholder = () => {
    return <div className="editor-placeholder">{t('dynamic-components:richText.placeholder')}</div>;
};

interface EditorProp {
    editorProp: RTEProps;
}
const Editor = ({editorProp}: EditorProp) => {
    return (
        <div className="editor-container">
            <ToolbarPlugin editorProp={editorProp} />
            <div className="editor-inner">
                <RichTextPlugin
                    contentEditable={
                        <ContentEditable
                            className="editor-input"
                            ref={editorProp.forwardedRef}
                            data-testid={editorProp.dataTestId}
                        />
                    }
                    placeholder={<Placeholder />}
                    ErrorBoundary={LexicalErrorBoundary}
                />
                <HistoryPlugin />

                <OnChangePlugin editorProp={editorProp} />
                <CodeHighlightPlugin />
                <ListPlugin />
                <CheckListPlugin />
                <LinkPlugin />
                <PlaygroundAutoLinkPlugin />
                <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
                <TabIndentationPlugin />
                <Footer editorProp={editorProp} />
            </div>
        </div>
    );
};

export default Editor;
