export enum ColumnWidth {
    MIN_WIDTH = 100,
    MAX_WIDTH = 500,
    COLUMN_DEFAULT = 200,
    TITLE_DEFAULT = 400,
}
export enum ModeEnum {
    Client = 'Client',
    Request = 'Request',
    Layout = 'Layout',
}

export enum LicenseType {
    Licensed = 'Employee',
    Unlicensed = 'Client',
}

export enum FieldType {
    Inline = 'Inline',
    Form = 'Form',
}

export enum TabKey {
    FIELDS = 'fields',
    COMMENTS = 'Comments',
}

export enum BannerType {
    SUCCESS = 'success',
    ERROR = 'error',
    IN_PROGRESS = 'info',
    PENDING = 'info',
}
export enum BannerStatus {
    COMPLETED = 'COMPLETED',
    FAILED = 'FAILED',
    IN_PROGRESS = 'INPROGRESS',
    PENDING = 'PENDING',
}
export enum Mode {
    View = 'view',
    Edit = 'edit',
}

export enum Layout {
    Form = 'form',
    Table = 'table',
    Kanban = 'kanban',
}

export enum Variant {
    View = 'view',
    Edit = 'edit',
    Hidden = 'hidden',
}

export enum ModalType {
    Save = 'save',
    Delete = 'delete',
    Cancel = 'cancel',
    Update = 'Update',
}

export enum QueryParams {
    record = 'record',
    viewRecord = 'viewRecord',
}
