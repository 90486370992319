const cellNavigation = (
    cellEvent: React.KeyboardEvent<HTMLDivElement>,
    enableEditMode: () => void,
    openButtonClick: () => void
) => {
    const {target} = cellEvent;

    if (cellEvent.key === 'Enter' || cellEvent.key === ' ') {
        cellEvent.preventDefault();
        cellEvent.stopPropagation();

        if (target instanceof HTMLElement) {
            if (target.classList.contains('open-btn')) {
                openButtonClick();
            } else {
                enableEditMode();
            }
        }
    }
};

export default cellNavigation;
