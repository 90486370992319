import React, {createContext, useContext} from 'react';

import {SharedTableProps} from '../DataTable';

export type TableContextType = SharedTableProps;

const TableContext = createContext<TableContextType | undefined>(undefined);

interface TableProviderProps extends SharedTableProps {
    children: React.ReactNode;
}
export const TableProvider: React.FC<TableProviderProps> = ({
    operations,
    onColumnAction,
    nonSortableColumns,
    filterValues,
    children,
    allowedColumnFields,
    allowedCurrencies,
    userList,
    enableComments,
    roleMapper,
    allowedDateFormat,
}) => {
    return (
        <TableContext.Provider
            value={{
                operations,
                onColumnAction,
                filterValues,
                nonSortableColumns,
                allowedColumnFields,
                allowedCurrencies,
                userList,
                enableComments,
                roleMapper,
                allowedDateFormat,
            }}
        >
            {children}
        </TableContext.Provider>
    );
};

export const useTableContext = () => {
    const context = useContext(TableContext);
    if (!context) {
        throw new Error('useTableContext must be used within a TableProvider');
    }
    return context;
};
