import styled from '@emotion/styled';
import {sizes} from '@sharefiledev/antd-config';
import {Select, Typography} from 'antd';

export const DateContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 4px;
    margin-bottom: ${sizes.base};
`;

export const StyledLabel = styled(Typography.Text)`
    margin-bottom: 5px;
    display: block;
`;

export const StyledSelect = styled(Select)`
    width: 100%;
`;

export const StyleTimeLabel = styled(Typography.Text)`
    margin-right: 4px;
`;

export const TimeContainer = styled.div`
    width: 100%;
    padding: 4px;
    margin-bottom: ${sizes.base};
`;
