import styled from '@emotion/styled';
import {Input} from 'antd';

import {FieldType} from '../../../enum';
import {InputFieldProps} from '../Fields';
import {useFieldOperations} from '../hooks/useFieldOperations';

const {TextArea} = Input;

const TextAreaFieldWraper = styled.div<{isInline: boolean}>`
    width: ${({isInline}) => (isInline ? '400px' : '100%')};
    top: 8px;
    z-index: 10 !important;
`;

export const TextAreaField = ({
    forwardedRef,
    fieldValue,
    onChange,
    onCancel,
    required,
    validations,
    dataTestId,
    className,
    fieldType,
    disabled,
}: InputFieldProps) => {
    const {inputValue, handleOnChange, isError, handleOnBlur, handleKeyDown} = useFieldOperations(
        fieldType,
        fieldValue,
        required,
        validations,
        onChange,
        onCancel
    );
    return (
        <TextAreaFieldWraper className="textarea-field" isInline={fieldType === FieldType.Inline}>
            <TextArea
                value={inputValue}
                onChange={(event) => handleOnChange(event.target.value)}
                onBlur={handleOnBlur}
                ref={forwardedRef}
                status={isError ? 'error' : ''}
                data-testid={dataTestId}
                className={className}
                autoFocus
                onKeyDown={handleKeyDown}
                disabled={disabled}
            />
        </TextAreaFieldWraper>
    );
};
