import {TabContextProps} from '@sharefiledev/flow-web';
import type {} from '@sharefiledev/sharefile-appshell'; // force tsc to resolve this package

const prefix = 'urn';
const namespace = 'sfextslot';
const owner = 'dc-pilet';
const modalNamespace = 'sfmodal';

export const dataTablesExtensionSlots = {
    projectTab: `${prefix}:${namespace}:${owner}:project:tab:data-tables` as const,
    dataTable: `${prefix}:${namespace}:${owner}:data-table` as const,
    conversationTab: `${prefix}:${namespace}:${owner}:drawer:comments:tab` as const,
};

export const piletOverlays = {
    createTableModal: `${prefix}:${modalNamespace}:${owner}:createtable` as const, //urn:sfmodal:dc-pilet:createtable
    editTableModal: `${prefix}:${modalNamespace}:${owner}:edittable` as const, //urn:sfmodal:dc-pilet:edittable
    deleteTableModal: `${prefix}:${modalNamespace}:${owner}:deletetable` as const, //urn:sfmodal:dc-pilet:deletetable
    clinetViewRequestRecordViewDrawer: 'urn:sfdrawer:task-aggregator:client:datatable' as const,
    viewRequestRecordViewDrawer: 'urn:sfdrawer:task-aggregator:datatable' as const,
    exportTableModal: `${prefix}:${modalNamespace}:${owner}:exporttable` as const, //urn:sfmodal:dc-pilet:exporttable
};

type FlowWebTabExtensionImplementationParams<TParams> = TParams & {
    tabContext: TabContextProps;
    piletSortWeight?: number;
};

export type ProjectDataTablesTabParams = FlowWebTabExtensionImplementationParams<{
    projectUri: string;
}>;

export type ConversationTabParams = {
    tabContext: any;
    projectUri: string;
    pendoTrackingNamespace: string;
    enableFilters: boolean;
};
export interface VerticalizationParams {
    verticalContextKey?: string;
}
interface RequestTableRecordViewDrawerOptions extends VerticalizationParams {
    containerRID: string;
    readonly?: boolean;
}

export type {RequestTableRecordViewDrawerOptions};

export type ClientRequestListDrawerOptions = {
    params: {containerRID: string};
};

export type CreateTableModalParams = {
    containerRID: string;
};

export type ExportTableModalParams = {
    tableRID: string;
    containerRID: string;
    flowId?: string;
};

type TableRIDParams = {
    tableRID: string;
};

export type EditTableModalParams = TableRIDParams;
export type DeleteTableModalParams = TableRIDParams;

export interface CreateTableSuccessReturnData {
    tableName: string;
    containerRID: string;
    tableRID: string;
}

export interface EditTableSuccessReturnData {
    tableName: string;
    tableRID: string;
}

export interface DeleteTableSuccessReturnData {
    tableRID: string;
}

export interface ExportTableSuccessReturnData {
    exportResourceRID: string;
    exportRequestId: string;
}

declare module '@sharefiledev/sharefile-appshell' {
    interface PiralCustomExtensionSlotMap {
        [dataTablesExtensionSlots.projectTab]: ProjectDataTablesTabParams;
        //any should replace with exact type.
        [dataTablesExtensionSlots.dataTable]: any;
        [dataTablesExtensionSlots.conversationTab]: ConversationTabParams;
    }
    interface PiralCustomOverlayOptionsMap {
        [piletOverlays.viewRequestRecordViewDrawer]: RequestTableRecordViewDrawerOptions;
        [piletOverlays.clinetViewRequestRecordViewDrawer]: ClientRequestListDrawerOptions;
        [piletOverlays.createTableModal]: CreateTableModalParams;
        [piletOverlays.editTableModal]: EditTableModalParams;
        [piletOverlays.deleteTableModal]: DeleteTableModalParams;
        [piletOverlays.exportTableModal]: ExportTableModalParams;
    }

    interface PiralCustomOverlayDataMap {
        [piletOverlays.createTableModal]: CreateTableSuccessReturnData;
        [piletOverlays.editTableModal]: EditTableSuccessReturnData;
        [piletOverlays.deleteTableModal]: DeleteTableSuccessReturnData;
        [piletOverlays.exportTableModal]: ExportTableSuccessReturnData;
    }
}

export enum TableEntityType {
    project = 'project',
    table = 'table',
    record = 'dcr',
}
