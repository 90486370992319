import styled from '@emotion/styled';
import {sizes} from '@sharefiledev/antd-config';

export const ConversationRowMetaDataWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 ${sizes.XXS};
    min-width: 50px;
    overflow: hidden;

    [data-testid='ConversationsBadgeMessagesCount'] {
        margin: 0;
        margin-left: 4px;
    }
`;
