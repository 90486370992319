import {Typography} from 'antd';
import AnchorLink from 'antd/es/anchor/AnchorLink';

import {InputFieldProps} from '../Fields';
import {LinkBox} from '../Fields.styled';

const {Text} = Typography;
export const LinkField = ({fieldValue}: InputFieldProps) => {
    const fixUrlPrefix = (url: string) => (url.startsWith('https://') ? url : 'https://' + url);

    const isValidUrl = (urlString: string) => {
        try {
            return new URL(fixUrlPrefix(urlString)).href === fixUrlPrefix(urlString);
        } catch (error) {
            return false;
        }
    };

    if (!isValidUrl || !fieldValue) {
        return <Text>{fieldValue}</Text>;
    }

    return (
        <LinkBox
            onClick={(e) => {
                e.stopPropagation();
            }}
        >
            <AnchorLink
                href={fixUrlPrefix(fieldValue as string)}
                target="_blank"
                title={fixUrlPrefix(fieldValue as string)}
                className="link-field"
            />
        </LinkBox>
    );
};
