import {TablesResponse} from '../layouts/Layouts';
import {ExportStatusQueryParams, RecordQueryParam, UserDetailsResponse} from '../model/types';
import apiClient from './apiClient';

export const getTables = async (inputRID: string) => {
    try {
        const response: TablesResponse[] = await apiClient.get(`/tables/${inputRID}/list`);
        return response;
    } catch (error) {
        throw new Error(`Error getting table: ${error}`);
    }
};

export const getRecords = async (tableId: string, queryParams: RecordQueryParam) => {
    try {
        const response: any = await apiClient.get(`/records/${tableId}/list`, {
            params: queryParams,
        });
        return response;
    } catch (error) {
        throw new Error(`Error getting records: ${error}`);
    }
};

export const updateRecord = async (recordId: string, data: any) => {
    // TODO: Check if api call is really required by comparing old values
    try {
        const response: any = await apiClient.patch(`/records/${recordId}`, data);
        return response;
    } catch (error) {
        throw new Error(`Error creating data for a table`);
    }
};

export const getUsersList = async (containerRID: string) => {
    try {
        const response: UserDetailsResponse = await apiClient.get(`/users/${containerRID}/list`);
        return response;
    } catch (error) {
        throw new Error(`Error getting user list: ${error}`);
    }
};

export const getUserPermissionsList = async (containerRID: string) => {
    try {
        const response: string[] = await apiClient.get(`/users/${containerRID}/permissions`);
        return response;
    } catch (error) {
        throw new Error(`Error getting user permissions: ${error}`);
    }
};

export const createTableRecord = async (payload: any, tableId: string, recordRID = '') => {
    try {
        const response: any = await apiClient.post(`/records/`, {
            tableRID: tableId,
            payload: payload,
            recordRID: recordRID,
        });
        return response;
    } catch (error) {
        throw new Error(`Error creating data for a table`);
    }
};

export const deleteTableRecord = async (recordId: string) => {
    try {
        const response: any = await apiClient.delete(`/records/${recordId}`);
        return response;
    } catch (error) {
        throw new Error(`Error deleting data for a table`);
    }
};

export const createTable = async (payload: any) => {
    try {
        const response: any = await apiClient.post(`/tables/`, payload);
        return response;
    } catch (error) {
        throw new Error(`Error creating a table`);
    }
};

export const updateTable = async (payload: any, tableId: string) => {
    try {
        const response: any = await apiClient.put(`/tables/${tableId}`, payload);
        return response;
    } catch (error) {
        throw new Error(`Error updating the table`);
    }
};

export const getTable = async (tableId: string) => {
    try {
        const response: TablesResponse = await apiClient.get(`/tables/${tableId}`);
        return response;
    } catch (error) {
        throw new Error(`Error getting table by Id`);
    }
};

export const deleteTable = async (tableId: string) => {
    try {
        const response: any = await apiClient.delete(`/tables/${tableId}`);
        return response;
    } catch (error) {
        throw new Error(`Error while deleting table`);
    }
};

export const getTableRecord = async (recordId: string) => {
    try {
        const response: any = await apiClient.get(`/records/${recordId}`);
        return response;
    } catch (error: any) {
        throw new Error(error.message || `Error getting table record by record Id`);
    }
};

export const getRecordPlaceholder = async (tableID: string) => {
    try {
        const response: any = await apiClient.get(`/records/placeholder/${tableID}`);
        return response;
    } catch (error) {
        throw new Error(`Error getting placeholder RID for record`);
    }
};

export const acknowledgeExportStatus = async (
    requestId: string,
    isNotificationAcknowledged: boolean
) => {
    try {
        const response: any = await apiClient.patch(`export/${requestId}`, {
            isNotificationAcknowledged,
        });
        return response;
    } catch (error) {
        throw new Error(`Error updating acknowledge export status`);
    }
};

export const getExportRequestStatus = async (queryParams: ExportStatusQueryParams) => {
    try {
        const response: any = await apiClient.get(`export/status`, {
            params: queryParams,
        });
        return response;
    } catch (error) {
        throw new Error(`Error getting export request status`);
    }
};
