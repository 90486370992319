export const NoRecords = () => (
    <svg
        width="282"
        height="263"
        viewBox="0 0 282 263"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_1295_26459)">
            <path
                d="M131.095 14.8901H60.4653L3.78516 34.8451V262.095H74.4153L131.095 242.07V14.8901Z"
                fill="white"
            />
            <path
                d="M132.096 101.59H130.096V16.3052L74.751 35.7902L74.0859 33.9052L130.766 13.9502L132.096 14.8902V101.59Z"
                fill="#0C0D0D"
            />
            <path
                d="M74.751 263.04L74.0859 261.155L130.096 241.36V101.59H132.096V242.07L131.431 243.01L74.751 263.04Z"
                fill="#0C0D0D"
            />
            <path
                d="M74.4153 263.095H3.78516L2.78516 262.095V34.8452L3.78516 33.8452H74.4153L75.4153 34.8452V262.095L74.4153 263.095ZM4.78516 261.095H73.4153V35.8452H4.78516V261.095Z"
                fill="#0C0D0D"
            />
            <path
                d="M4.78516 44.9401H2.78516V34.8451L3.4502 33.9051L60.1353 13.9501L60.4653 13.8901H131.095L132.095 14.8901V22.5151H130.095V15.8901H60.6353L4.78516 35.5551V44.9401Z"
                fill="#0C0D0D"
            />
            <path
                d="M90.3398 208.785V223.615L116.625 214.36V199.53L90.3398 208.785Z"
                fill="#CCEAFF"
            />
            <path
                d="M82.9707 247.885L81.6406 246.945V188.115L82.3057 187.17L118.996 174.255L119.661 176.14L83.6406 188.825V245.53L122.821 231.74V175.8H124.821V232.445L124.151 233.39L82.9707 247.885Z"
                fill="#0C0D0D"
            />
            <path
                d="M90.6758 224.515L89.3457 223.57V208.74L90.0156 207.795L116.301 198.54L117.631 199.485V214.315L116.961 215.26L90.6758 224.515ZM91.3408 209.45V222.16L115.626 213.61V200.9L91.3408 209.45Z"
                fill="#0C0D0D"
            />
            <path
                d="M96.745 215.005C96.33 215.005 95.9449 214.75 95.7999 214.335C95.6149 213.815 95.8898 213.245 96.4098 213.06L110.485 208.105C111.005 207.92 111.575 208.195 111.76 208.715C111.945 209.235 111.67 209.805 111.15 209.99L97.0748 214.945C96.9648 214.985 96.855 215 96.745 215V215.005Z"
                fill="#0C0D0D"
            />
            <path d="M213.031 129.24H82.6406V136.735H213.031V129.24Z" fill="#CCEAFF" />
            <path d="M213.031 156.25H82.6406V163.745H213.031V156.25Z" fill="#CCEAFF" />
            <path
                d="M82.6406 116.42V129.24H213.031V136.735H82.6406V156.25H213.031V163.745H82.6406V175.25H237.926V116.42H82.6406Z"
                fill="#CCEAFF"
            />
            <path
                d="M245.676 135.795V150.625L271.956 141.375V126.545L245.676 135.795Z"
                fill="#CCEAFF"
            />
            <path
                d="M237.926 116.42V175.25L279.106 160.75V101.92L237.926 116.415V116.42ZM271.456 141.375L245.171 150.63V135.8L271.456 126.545V141.375Z"
                fill="white"
            />
            <path
                d="M83.791 116.015L123.821 101.92H165.766C165.766 101.92 172.156 103.685 177.376 104.1C182.591 104.515 193.471 105.805 199.196 109.17C204.921 112.535 206.971 113.56 208.551 113.09C210.131 112.62 215.261 111.675 215.261 111.675C215.261 111.675 218.526 111.665 218.871 110.33C219.216 108.995 212.591 104.53 212.591 104.53L213.036 103.06L217.841 105.02L219.946 104.895L220.721 103.06L226.161 101.925H277.841L237.931 116.425L83.7959 116.02L83.791 116.015Z"
                fill="#2CA6FF"
            />
            <path
                d="M237.926 176.25H82.6406L81.6406 175.25V116.42L82.3057 115.475L123.491 100.98L123.821 100.92H165.771V102.92H123.991L83.6406 117.13V174.25H237.756L242.176 172.69L242.836 174.58L238.256 176.19L237.926 176.25Z"
                fill="#0C0D0D"
            />
            <path
                d="M245.705 151.57L244.375 150.625V135.795L245.045 134.85L271.33 125.595L272.66 126.54V141.37L271.99 142.315L245.705 151.57ZM246.375 136.505V149.215L270.66 140.665V127.955L246.375 136.505Z"
                fill="#0C0D0D"
            />
            <path
                d="M251.776 142.065C251.361 142.065 250.976 141.81 250.831 141.395C250.646 140.875 250.921 140.305 251.441 140.12L265.516 135.165C266.036 134.98 266.606 135.255 266.791 135.775C266.976 136.295 266.701 136.865 266.181 137.05L252.106 142.005C251.996 142.045 251.886 142.06 251.776 142.06V142.065Z"
                fill="#0C0D0D"
            />
            <path d="M237.926 115.42H82.6406V117.42H237.926V115.42Z" fill="#0C0D0D" />
            <path d="M124.82 101.92H122.82V116.42H124.82V101.92Z" fill="#0C0D0D" />
            <path
                d="M238.256 176.19L236.926 175.245V116.415L237.596 115.47L278.776 100.97L280.106 101.915V160.745L279.436 161.69L238.256 176.19ZM238.926 117.13V173.84L278.106 160.045V103.335L238.926 117.13Z"
                fill="#0C0D0D"
            />
            <path
                d="M213.031 137.735H82.6406L81.6406 136.735V129.24L82.6406 128.24H213.031L214.031 129.24V136.735L213.031 137.735ZM83.6406 135.735H212.031V130.24H83.6406V135.735Z"
                fill="#0C0D0D"
            />
            <path
                d="M213.031 164.745H82.6406L81.6406 163.745V156.25L82.6406 155.25H213.031L214.031 156.25V163.745L213.031 164.745ZM83.6406 162.745H212.031V157.25H83.6406V162.745Z"
                fill="#0C0D0D"
            />
            <path
                d="M280.105 109.785H278.105V102.92H224.98V100.92H279.105L280.105 101.92V109.785Z"
                fill="#0C0D0D"
            />
            <path
                d="M90.3398 64.6505V79.4805L116.625 70.2255V55.3955L90.3398 64.6505Z"
                fill="#CCEAFF"
            />
            <path
                d="M82.9707 105.045L81.6406 104.1V45.2702L82.3108 44.3252L123.491 29.8252L124.821 30.7702V89.6002L124.151 90.5452L82.9707 105.045ZM83.6406 45.9802V102.69L122.821 88.8952V32.1852L83.6406 45.9802Z"
                fill="#0C0D0D"
            />
            <path
                d="M90.6758 80.4203L89.3457 79.4753V64.6453L90.0156 63.7003L116.301 54.4453L117.631 55.3903V70.2203L116.961 71.1653L90.6758 80.4203ZM91.3408 65.3603V78.0703L115.626 69.5203V56.8103L91.3408 65.3603Z"
                fill="#0C0D0D"
            />
            <path
                d="M96.745 70.9152C96.33 70.9152 95.9449 70.6602 95.7999 70.2452C95.6149 69.7252 95.8898 69.1552 96.4098 68.9702L110.485 64.0152C111.005 63.8302 111.575 64.1052 111.76 64.6252C111.945 65.1452 111.67 65.7152 111.15 65.9002L97.0748 70.8552C96.9648 70.8952 96.855 70.9102 96.745 70.9102V70.9152Z"
                fill="#0C0D0D"
            />
            <path
                d="M162.686 70.7744L179.946 74.1994C179.946 74.1994 184.055 60.3944 183.76 53.1194C183.465 45.8444 181.361 42.5244 181.361 42.5244C181.361 42.5244 172.675 43.4794 168.51 50.8244C164.345 58.1694 162.686 70.7744 162.686 70.7744Z"
                fill="#0C0D0D"
            />
            <path
                d="M221.42 73.7646L230.065 72.1546C230.065 72.1546 228.875 58.7847 223.325 49.7297C218.91 42.5297 215.16 41.8896 215.16 41.8896L216.44 48.0846L215.765 55.9996L221.425 73.7646H221.42Z"
                fill="#0C0D0D"
            />
            <path d="M163.101 174.67L159.531 229.82H193.036L191.611 174.67" fill="#0C0D0D" />
            <path d="M228.411 174.67L231.987 229.82H198.477L199.901 174.67" fill="#0C0D0D" />
            <path
                d="M186.696 16.7447L186.596 8.91467L189.886 3.62466L194.771 1.28467L202.276 1.45966L209.666 6.08466L212.456 5.54967L220.031 6.16966L224.571 14.2847L226.056 21.9047L222.101 29.1047L215.526 30.8747L211.301 27.0847L214.636 24.2097L215.476 21.6897L214.751 18.0547L211.326 17.1447L206.326 20.7797L201.026 17.8797L197.961 15.7997L196.131 13.6097L194.946 12.0897L193.766 10.4047L190.696 13.9547L186.696 16.7447Z"
                fill="#0C0D0D"
            />
            <path
                d="M196.041 38.5747C194.701 38.5747 193.266 38.2447 191.801 37.3947C187.706 35.0297 185.611 30.9097 186.056 26.0997C186.211 24.4297 186.451 23.4547 186.621 22.7447C186.901 21.6047 186.986 21.2597 186.251 19.0897C186.186 18.8997 186.121 18.7097 186.051 18.5097C185.021 15.5697 183.466 11.1347 187.581 4.74469C189.641 1.54969 194.156 -0.345316 199.091 -0.0703164C204.581 0.234684 209.241 3.18468 211.551 7.82468C214.646 14.0397 212.961 17.5797 212.891 17.7297L211.101 16.8397C211.101 16.8397 212.371 13.9597 209.761 8.71468C207.746 4.66968 203.821 2.19469 198.986 1.92469C194.821 1.69469 190.916 3.25968 189.266 5.82468C185.681 11.3897 186.991 15.1197 187.941 17.8447C188.011 18.0497 188.086 18.2497 188.151 18.4447C189.041 21.0647 188.931 21.7597 188.571 23.2197C188.401 23.9047 188.191 24.7597 188.051 26.2847C187.836 28.6347 188.166 32.9797 192.806 35.6647C197.416 38.3297 201.206 34.3697 201.366 34.1997L202.831 35.5597C201.641 36.8447 199.101 38.5697 196.046 38.5697L196.041 38.5747Z"
                fill="#0C0D0D"
            />
            <path
                d="M208.15 32.7347L207.9 27.0397L209.035 26.0047C209.16 26.0197 212.36 26.3497 213.89 22.1847C214.18 21.3947 214.19 20.1547 213.65 19.3097C213.26 18.6997 212.65 18.3697 211.785 18.2947C210.21 18.1547 208.73 20.2747 208.34 21.0247L207.7 21.5297C207.385 21.6097 204.465 22.2147 199.135 18.6697C196.89 17.1797 194.75 13.8547 193.555 11.7697C193.1 12.5647 192.425 13.5447 191.435 14.6247C189.005 17.2897 187.21 18.1797 187.135 18.2197L186.255 16.4247L186.695 17.3247L186.25 16.4297C186.25 16.4297 187.81 15.6297 189.955 13.2847C192.045 10.9947 192.495 9.29971 192.495 9.27971L194.365 9.07471C194.395 9.13471 197.36 15.0947 200.235 17.0097C203.74 19.3397 205.965 19.6147 206.82 19.6147H206.84C207.52 18.5397 209.37 16.0897 211.94 16.3047C213.82 16.4647 214.82 17.4447 215.325 18.2347C216.26 19.7047 216.215 21.6247 215.755 22.8747C214.405 26.5547 211.785 27.7697 209.935 27.9847L210.14 32.6447L208.14 32.7347H208.15Z"
                fill="#0C0D0D"
            />
            <path
                d="M217.094 31.8746C213.204 31.8746 211.025 29.0896 210.375 27.4546L212.235 26.7146L211.304 27.0846L212.235 26.7096C212.295 26.8646 213.834 30.4746 218.169 29.7846C220.604 29.3946 222.7 27.7446 223.915 25.2496C225.895 21.1846 225.3 15.7346 222.28 10.2946C220.955 7.90461 219.27 6.46961 217.28 6.03461C213.82 5.27461 210.484 7.67461 210.449 7.69961L209.27 6.08461C209.435 5.96461 213.379 3.12961 217.709 4.07961C220.289 4.64461 222.415 6.40961 224.03 9.32461C227.37 15.3446 227.98 21.4696 225.715 26.1296C224.21 29.2196 221.575 31.2746 218.485 31.7646C218 31.8396 217.54 31.8796 217.1 31.8796L217.094 31.8746Z"
                fill="#0C0D0D"
            />
            <path
                d="M180.326 75.8197L161.701 72.4847L160.906 71.2697L164.521 56.0497C166.241 48.7947 172.056 43.0397 179.326 41.3847L189.506 39.0747L189.951 41.0247L179.771 43.3347C173.236 44.8197 168.016 49.9897 166.471 56.5097L163.101 70.7047L180.681 73.8547L180.326 75.8247V75.8197Z"
                fill="#0C0D0D"
            />
            <path
                d="M213.581 99.6047C213.546 99.5797 209.705 97.0648 204.595 97.1298C202.045 97.1498 200.085 96.3697 197.355 95.2747C196.365 94.8797 195.246 94.4297 193.966 93.9697C189.386 92.3197 177.28 89.6348 177.16 89.6097L176.4 88.4147C176.455 88.1747 181.796 64.5347 182.706 60.7647C183.606 57.0247 183.83 50.5397 182.72 46.8397C181.7 43.4397 180.945 42.6298 180.94 42.6198C180.97 42.6498 180.986 42.6598 180.986 42.6598L182.255 41.1147C182.515 41.3297 183.455 42.3348 184.635 46.2598C185.875 50.3997 185.61 57.2497 184.65 61.2297C183.85 64.5497 179.596 83.3497 178.571 87.8747C181.591 88.5597 190.665 90.6547 194.64 92.0897C195.955 92.5647 197.09 93.0197 198.095 93.4197C200.705 94.4697 202.44 95.1597 204.565 95.1347C204.64 95.1347 204.71 95.1347 204.785 95.1347C210.41 95.1347 214.511 97.8298 214.686 97.9448L213.575 99.6097L213.581 99.6047Z"
                fill="#0C0D0D"
            />
            <path
                d="M216.739 56.2045L214.784 55.7895C214.789 55.7595 215.439 52.6395 215.439 48.5545C215.439 44.4695 214.214 42.2245 214.199 42.1995L215.969 41.2695C216.029 41.3845 217.434 44.1095 217.434 48.5545C217.434 52.9995 216.764 56.0695 216.739 56.2045Z"
                fill="#0C0D0D"
            />
            <path
                d="M197.401 52.2948C197.221 52.1748 192.981 49.3898 191.641 47.9848C190.016 46.2798 191.176 42.8498 191.426 42.1748L193.301 42.8698C192.861 44.0648 192.541 46.0298 193.086 46.5998C194.066 47.6298 197.326 49.8448 198.496 50.6148L197.401 52.2898V52.2948Z"
                fill="#0C0D0D"
            />
            <path
                d="M221.746 104.059C219.841 104.059 217.271 103.314 214.386 100.479C210.796 96.9594 207.561 84.0844 204.966 73.7444C203.631 68.4344 202.481 63.8494 201.681 62.1944C200.091 58.9094 197.481 56.8894 195.381 55.2644C194.456 54.5494 193.581 53.8694 192.916 53.1794C190.771 50.9494 191.636 48.7494 192.391 47.8994L193.896 49.2144C193.741 49.4044 193.026 50.4094 194.356 51.7894C194.916 52.3744 195.701 52.9794 196.601 53.6794C198.866 55.4344 201.686 57.6144 203.476 61.3244C204.366 63.1594 205.481 67.6144 206.901 73.2544C209.306 82.8194 212.596 95.9244 215.786 99.0494C218.116 101.334 220.476 102.349 222.611 101.984C224.401 101.674 225.936 100.379 226.936 98.3294C228.661 94.7994 227.136 78.6794 226.406 72.7694L228.391 72.5244C228.666 74.7594 231.031 94.5044 228.736 99.2094C227.446 101.849 225.391 103.534 222.956 103.954C222.591 104.019 222.191 104.054 221.751 104.054L221.746 104.059Z"
                fill="#0C0D0D"
            />
            <path
                d="M221.605 74.7444L221.24 72.7794L228.915 71.3494C228.34 68.2044 226.27 57.9644 222.43 50.1694C218.09 41.3494 209.47 41.3694 209.125 41.3744L209.09 39.3744C209.5 39.3694 219.31 39.3044 224.22 49.2844C228.925 58.8344 230.97 71.4594 231.055 71.9944L230.25 73.1344L221.605 74.7444Z"
                fill="#0C0D0D"
            />
            <path
                d="M222.447 79.4546C222.372 79.2096 214.877 55.0146 212.317 50.9296C211.322 49.3446 210.482 48.3546 209.802 47.5646C208.667 46.2346 207.847 45.2746 207.672 43.0696C207.557 41.6396 208.272 39.9246 208.967 38.2646C209.572 36.8146 210.327 35.0146 210.007 34.2946C209.977 34.2296 209.907 34.0696 209.537 33.9446C208.587 33.6146 206.922 36.3946 205.932 38.0546C204.917 39.7396 204.042 41.1996 203.052 41.8346C200.552 43.4346 199.547 43.3396 196.302 41.2146C195.342 40.5846 194.252 39.6796 193.202 38.7996C191.932 37.7446 189.602 35.8046 188.832 35.8046C188.822 35.8046 188.817 35.8046 188.807 35.8046C188.707 35.9396 188.612 36.1446 188.672 36.5096C189.092 39.0446 195.157 43.7096 198.882 46.0496L197.817 47.7446C196.752 47.0746 187.402 41.0846 186.702 36.8346C186.557 35.9596 186.757 35.1546 187.282 34.5046C187.607 34.1046 188.057 33.8646 188.577 33.8146C190.152 33.6646 192.162 35.3296 194.482 37.2596C195.492 38.0996 196.537 38.9696 197.402 39.5396C200.127 41.3296 200.302 41.2196 201.977 40.1496C202.577 39.7646 203.452 38.3096 204.222 37.0246C205.857 34.2946 207.717 31.2046 210.192 32.0546C210.982 32.3246 211.537 32.8046 211.837 33.4796C212.512 34.9846 211.692 36.9546 210.817 39.0396C210.217 40.4796 209.592 41.9646 209.672 42.9096C209.797 44.4696 210.277 45.0346 211.332 46.2696C212.052 47.1096 212.942 48.1596 214.017 49.8696C216.722 54.1746 224.057 77.8646 224.367 78.8696L222.457 79.4596L222.447 79.4546Z"
                fill="#0C0D0D"
            />
            <path
                d="M207.562 114.089C206.892 114.089 206.157 114.004 205.392 113.779C204.122 113.404 203.122 112.665 202.062 111.88C201.232 111.264 200.372 110.625 199.267 110.064C194.832 107.814 192.867 106.975 184.447 105.755C182.652 105.495 180.867 105.344 178.797 105.169C176.097 104.944 173.042 104.684 169.142 104.109C163.787 103.314 160.192 99.0945 160.787 94.2895L163.557 71.8545L165.542 72.0995L162.772 94.5345C162.247 98.7945 165.727 101.58 169.437 102.13C173.272 102.7 176.297 102.955 178.967 103.174C181.067 103.349 182.882 103.505 184.737 103.77C193.557 105.045 195.717 106.014 200.177 108.274C201.432 108.909 202.402 109.635 203.262 110.27C204.212 110.975 205.032 111.584 205.962 111.859C208.227 112.524 210.162 111.519 210.182 111.509L211.132 113.27C211.052 113.314 209.597 114.085 207.572 114.085L207.562 114.089Z"
                fill="#0C0D0D"
            />
            <path
                d="M212.452 113.6C212.177 113.6 211.892 113.58 211.597 113.54C209.297 113.21 204.277 108.62 203.297 107.705L204.662 106.245C206.917 108.345 210.647 111.385 211.882 111.565C213.717 111.825 214.837 110.745 214.882 110.7L216.307 112.1C216.242 112.17 214.812 113.605 212.452 113.605V113.6Z"
                fill="#0C0D0D"
            />
            <path
                d="M217.176 112.675C215.511 112.675 213.301 111.535 210.441 109.195C208.351 107.485 206.711 105.78 206.641 105.71L208.086 104.325C210.466 106.81 214.986 110.675 217.161 110.675C217.196 110.675 217.231 110.675 217.266 110.675C217.606 110.655 217.761 110.515 217.866 110.38C217.756 109.805 215.891 108.475 214.886 107.755C212.696 106.19 210.806 104.84 211.336 103.19C211.571 102.45 212.031 102.14 212.371 102.005C213.321 101.635 214.326 102.21 215.491 102.88C216.676 103.555 217.996 104.315 219.146 104.1L219.761 102.2L221.666 102.815L220.896 105.205L220.331 105.82C218.186 106.72 216.061 105.505 214.501 104.615C214.191 104.44 213.786 104.205 213.466 104.055C213.971 104.645 215.231 105.55 216.051 106.13C217.891 107.445 219.631 108.69 219.851 110.105C219.931 110.63 219.806 111.14 219.476 111.575C218.976 112.24 218.251 112.625 217.386 112.675C217.316 112.675 217.246 112.68 217.176 112.68V112.675Z"
                fill="#0C0D0D"
            />
            <path
                d="M193.13 230.82H159.625L158.625 229.755L162.195 174.604L164.19 174.734L160.69 228.82H192.105L190.705 174.699L192.705 174.645L194.13 229.795L193.13 230.82Z"
                fill="#0C0D0D"
            />
            <path
                d="M189.811 247.93H155.551L154.676 246.44L164.271 229.33L166.016 230.31L157.256 245.93H188.811V229.82H190.811V246.93L189.811 247.93Z"
                fill="#0C0D0D"
            />
            <path
                d="M232.075 230.82H198.57L197.57 229.795L198.995 174.645L200.995 174.699L199.595 228.82H231.01L227.505 174.734L229.505 174.604L233.075 229.755L232.075 230.82Z"
                fill="#0C0D0D"
            />
            <path
                d="M236.151 247.93H201.891L200.891 246.93V229.82H202.891V245.93H234.446L225.686 230.31L227.431 229.33L237.026 246.44L236.151 247.93Z"
                fill="#0C0D0D"
            />
        </g>
        <defs>
            <clipPath id="clip0_1295_26459">
                <rect width="281.5" height="263" fill="white" transform="translate(0.25)" />
            </clipPath>
        </defs>
    </svg>
);
