/* istanbul ignore file */
import {createScopedInstanceAndLoader, getLanguage} from '@citrite/translate';

import {languages} from '../locales';

const instance: any = createScopedInstanceAndLoader({
    namespace: 'dynamic-components',
    resources: languages,
    localesLoaderMap: {
        en: () => import('../locales/en.json'),
        es: () => import('../locales/es.json'),
        ja: () => import('../locales/ja.json'),
        de: () => import('../locales/de.json'),
        fr: () => import('../locales/fr.json'),
        'pt-BR': () => import('../locales/pt-BR.json'),
        it: () => import('../locales/it.json'),
        nl: () => import('../locales/nl.json'),
    },
    compatibilityJSON: 'v4',
});

type TFunction = typeof instance.t;
type TKey = Parameters<TFunction>[0];
export type PluginTFunction = (key: TKey, options?: any) => string;

export const {LocalizationLoader, t} = instance;

export {getLanguage};
