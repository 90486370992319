import {Input} from 'antd';
import {ChangeEvent, KeyboardEvent, useCallback, useEffect, useState} from 'react';

import {DURATION_SEPARATOR, MAX_LENGTH_DURATION} from '../../../constants/constants';
import {t} from '../../../utils';
import {InputFieldProps} from '../Fields';
import {useFieldOperations} from '../hooks/useFieldOperations';
import {isNullOrUndefined} from '../utils';

const formatDurationInput = (rawValue: string): string => {
    let value = rawValue.replace(/\D/g, '');

    if (value.length > MAX_LENGTH_DURATION) {
        value = value.slice(0, MAX_LENGTH_DURATION);
    } else if (value.length > 2) {
        value = value.slice(0, 2) + DURATION_SEPARATOR + value.slice(2, 4);
    }

    if (value.length === MAX_LENGTH_DURATION) {
        const minutes = parseInt(value.split(DURATION_SEPARATOR)[1], 10);
        if (minutes > 59) {
            value = value.slice(0, 3); // Reset to "xx:"
        }
    }

    return value;
};
const parseDurationStringToMinutes = (value: string): number => {
    if (value.length && value.length <= 2) {
        return parseInt(value) * 60;
    }
    const [hours, minutes] = value.split(':').map(Number);
    if (value.length && value.length === 4 && minutes < 6) {
        return hours * 60 + minutes * 10;
    }

    return hours * 60 + minutes;
};
const DurationField = ({
    disabled,
    fieldValue,
    forwardedRef,
    required,
    validations,
    onChange,
    onCancel,
    className,
    dataTestId,
    fieldType,
}: InputFieldProps) => {
    const [duration, setDuration] = useState('');
    const {inputValue, handleOnChange, handleOnBlur, handleKeyDown} = useFieldOperations<
        number | null
    >(
        fieldType,
        typeof fieldValue === 'number' ? fieldValue : null,
        required,
        validations,
        onChange,
        onCancel
    );

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (isNullOrUndefined(event?.target?.value)) return;
        const value = event?.target?.value.replace(/\D/g, '');
        const formattedValue = formatDurationInput(value);
        setDuration(formattedValue);
        const durationValue: number = parseDurationStringToMinutes(formattedValue);
        handleOnChange(durationValue);
    };

    const handleKeyDownLocal = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Backspace' && duration.length === 3) {
            setDuration(duration.slice(0, 2));
        }
        handleKeyDown(event);
    };

    const convertToTimeFormat = useCallback((minutes: number | null) => {
        if (isNullOrUndefined(minutes)) {
            setDuration('');
            return;
        }
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;

        const formattedTime = `${String(hours).padStart(2, '0')}:${String(
            remainingMinutes
        ).padStart(2, '0')}`;
        setDuration(formattedTime);
    }, []);

    useEffect(() => {
        convertToTimeFormat(inputValue);
    }, [convertToTimeFormat]);

    return (
        <Input
            value={duration}
            className={className}
            ref={forwardedRef}
            data-testid={dataTestId}
            disabled={disabled}
            autoFocus
            onChange={handleChange}
            onKeyDown={handleKeyDownLocal}
            onBlur={handleOnBlur}
            placeholder={t('dynamic-components:manageField.fields.durationField.placeholder')}
            maxLength={MAX_LENGTH_DURATION}
        />
    );
};

export default DurationField;
